import moment from 'moment';
import Cookie from 'js-cookie';
import {
    Catalyst_OnBoard_Header_Array,
    Class_Header_Array, 
    Event_Header_Array,
    Private_Header_Array,
    User_OnBoard_Header_Array
} from './DefaultInitialValues';

const Page_WISE_NAV_HELMET_DETAILS = [
    {
        PATH: "/schedule-details",
        NAV_LINK_LOGO: "white_text",
        NAV_BG_COLOR: "rs-body",
        NAV_LINK_COLOR: "color-primary-100",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR: "color-accent-90",
        LOGIN_BG_COLOR : "color-primary-50_bg",
        LOGIN_TEXT_COLOR : "white_text",
        H1_TEXT: "Home",
        H1_TEXT_COLOR: "color-primary-100",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 1,
        LOGO: "",
        HAMBURGER_COLOR: "white_text",
    },
    {
        PATH: "/events-classes",
        NAV_LINK_LOGO: "",
        NAV_BG_COLOR: "primary_10_bg",
        NAV_LINK_COLOR: "white_text",
        ACTIVE_NAV_LINK_COLOR: "",
        ACTIVE_LINK_UNDERLINE_COLOR: "",
        LOGIN_BG_COLOR : "accent_90_bg",
        LOGIN_TEXT_COLOR : "color-primary-50",
        H1_TEXT: "Events & Classes",
        H1_TEXT_COLOR: "white_text",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 1,
        LOGO: "white_logo",
        HAMBURGER_COLOR: "white_text",
    },
    {
        PATH: "/news",
        NAV_LINK_LOGO: "",
        NAV_BG_COLOR: "accent_100_bg",
        NAV_LINK_COLOR: "color-primary-100",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR: "color-accent-90",
        LOGIN_BG_COLOR : "color-primary-50_bg",
        LOGIN_TEXT_COLOR : "white_text",
        H1_TEXT: "News",
        H1_TEXT_COLOR: "color-primary-100",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 1,
        LOGO: "green_logo_with_text",
        HAMBURGER_COLOR: "white_text",
    },
    {
        PATH: "/about",
        NAV_LINK_LOGO: "white_text",
        NAV_BG_COLOR: "rs-body",
        NAV_LINK_COLOR: "color-primary-100",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR: "color-accent-90",
        LOGIN_BG_COLOR : "color-primary-50_bg",
        LOGIN_TEXT_COLOR : "white_text",
        H1_TEXT: "Home",
        H1_TEXT_COLOR: "color-primary-100",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 2,
        LOGO: "green_logo_with_text",
        HAMBURGER_COLOR: "color-primary-50",
    },
    {
        PATH: "/catalysts",
        NAV_LINK_LOGO: "color-accent-90",
        NAV_BG_COLOR: "color-primary-50_bg",
        NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR : "color-accent-90",
        LOGIN_BG_COLOR : "accent_90_bg",
        LOGIN_TEXT_COLOR : "color-primary-50",
        H1_TEXT: "Home",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 0,
        LOGO: "yellow_logo",
        HAMBURGER_COLOR: "color-accent-90",
    },
    {
        PATH: "catalyst/",
        NAV_LINK_LOGO: "color-accent-90",
        NAV_BG_COLOR: "color-primary-50_bg",
        NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR : "color-accent-90",
        LOGIN_BG_COLOR : "accent_90_bg",
        LOGIN_TEXT_COLOR : "color-primary-50",
        H1_TEXT: "Home",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 0,
        LOGO: "yellow_logo",
        HAMBURGER_COLOR: "color-accent-90",
    },
    {
        PATH: "/contact",
        NAV_LINK_LOGO: "white_text",
        NAV_BG_COLOR: "color-primary-50_bg",
        NAV_LINK_COLOR: "white_text",
        ACTIVE_NAV_LINK_COLOR: "white_text",
        ACTIVE_LINK_UNDERLINE_COLOR: "white_text",
        LOGIN_BG_COLOR : "accent_90_bg",
        LOGIN_TEXT_COLOR : "color-primary-50",
        H1_TEXT: "Home",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 3,
        LOGO: "white_logo",
        HAMBURGER_COLOR: "white_text",
    },
    {
        PATH: "/dashboard",
        NAV_LINK_LOGO: "white_text",
        NAV_BG_COLOR: "rs-body",
        NAV_LINK_COLOR: "color-primary-50",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR: "color-accent-90",
        LOGIN_BG_COLOR : "color-primary-50_bg",
        LOGIN_TEXT_COLOR : "white_text",
        H1_TEXT: "Home",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : -1,
        LOGO: "green_logo_with_text",
        HAMBURGER_COLOR: "white_text",
    },
    {
        PATH: "/becomeacatalyst",
        NAV_LINK_LOGO: "white_text",
        NAV_BG_COLOR: "rs-body",
        NAV_LINK_COLOR: "color-primary-100",
        ACTIVE_NAV_LINK_COLOR: "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR: "color-accent-90",
        LOGIN_BG_COLOR: "color-primary-50_bg",
        LOGIN_TEXT_COLOR: "color-secondary-70",
        H1_TEXT: "Home",
        H1_TEXT_COLOR: "color-primary-100",
        HEMLMET_BG: "",
        BREADCRUMB_LINK_COLOR: "",
        BREADCRUMB_SEPERATOR_COLOR: "",
        activeIndex : 0,
        LOGO: "green_logo_with_text",
        HAMBURGER_COLOR: "white_text",
    },
];

const getNavDetails = (locationVal) => {
    const obj = {};
    obj.activeIndex = -1;
    obj.PATH = "";
    obj.NAV_LINK_LOGO = "";
    obj.NAV_BG_COLOR = "fixed";
    obj.NAV_LINK_COLOR= "color-accent-90";
    obj.ACTIVE_NAV_LINK_COLOR = "color-accent-90";
    obj.ACTIVE_LINK_UNDERLINE_COLOR = "color-accent-90";
    obj.LOGIN_BG_COLOR = "accent_90_bg";
    obj.LOGIN_TEXT_COLOR = "color-primary-50";
    obj.H1_TEXT = "Home";
    obj.HEMLMET_BG = "";
    obj.BREADCRUMB_LINK_COLOR = "";
    obj.BREADCRUMB_SEPERATOR_COLOR = "";
    obj.LOGO = "yellow_logo";
    obj.HAMBURGER_COLOR = "color-accent-90"

    const filteredItem = Page_WISE_NAV_HELMET_DETAILS.filter((item, index) => ( (item.PATH === locationVal) || (locationVal.includes(item.PATH))));
    return filteredItem.length === 0 ? obj : filteredItem[0];
}

const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const dayNames     = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const getMonthText = (days = [], isMobileView = false, isDayView = false) => {

    if(days.length === 0) return "No Date selected";

    const startDate = days[0];
    const endDate = days[days.length - 1];

    if (!startDate || !endDate) return "No Date selected";

    const firsrtDay = startDate.split("-")[2];
    const endDay = endDate.split("-")[2];
    const monthVal = endDate.split("-")[1];
	const month_val = monthNames[parseInt(monthVal) - 1];

    var d = new Date(startDate);
    var dayName = dayNames[d.getDay()];
  
    if(isDayView === "true") {
        return `${dayName} ${firsrtDay} ${month_val}`;
    }
   
	return `${firsrtDay} - ${endDay} ${month_val}`;
}

const getMonthDayText = (d = "") => {
    if (!d) return "";

    const startDate = getFormattedDate(d);

    const firsrtDay = startDate.split("-")[2];
    const monthVal = startDate.split("-")[1];
    const month_val = monthNames[parseInt(monthVal) - 1];
    var date = new Date(startDate);
    var dayName = dayNames[date.getDay()];
  
    return `${dayName?.substring(0,3)} - ${firsrtDay} ${month_val}`; 
}

const getFormattedCurrentMonthFirstDate = () => {
// Create a new Date object for the current date
    let currentDate = new Date();

    // Set the date to the 1st of the current month
    currentDate.setDate(1);

    // Get the day, month, and year components
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1; // Note: getMonth() returns 0-indexed, so we add 1
    let year = currentDate.getFullYear();

    // Format the date as dd/MM/YYYY
    let formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
}

const getFormattedCurrentMonthTodayDate = () => {
// Create a new Date object for the current date
    let currentDate = new Date();

    // Get the day, month, and year components
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1; // Note: getMonth() returns 0-indexed, so we add 1
    let year = currentDate.getFullYear();

    // Format the date as dd/MM/YYYY
    let formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
}

const getCurrentMonthText = () => {
    let currentDate = new Date();

    // Get the month from the Date object (months are zero-indexed, so January is 0, February is 1, etc.)
    let currentMonth = currentDate.getMonth();

    // Since months are zero-indexed, add 1 to get the current month number
    currentMonth = currentMonth + 1; // January is 1, February is 2, ..., December is 12

    // Optionally, you can get the name of the month as well
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let currentMonthName = monthNames[currentDate.getMonth()];

    return currentMonthName;
}

const getFormattedDate = (d) => {
	const today = new Date(d);
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const formattedDate = yyyy + '-' + mm + '-' + dd;
	return formattedDate;
}

const addZeroBefore = (n) => {
  return (n < 10 ? '0' : '') + n;
}

const getHelmetDetails = (locationVal) => {
	let helmetBgColor = "";
	let h1Text = "";
	let h1TextColor = "";
	let breadcrumbLinkColor = "white_text";
    let breadcrumbEndLinkColor = "white_text";
    let arrow_color = "white_text";
       
    if(locationVal === "" || locationVal === "/") {
        helmetBgColor = "homeNavBar";
        h1Text = "Home";
    } else if(locationVal === "/events-classes") {
        helmetBgColor = "eventNavBar";
        h1TextColor = "white_text";
        h1Text = "Events & Classes";
    } else if(locationVal.includes("service-details/")) {
        h1TextColor = "color-primary-50";
        h1Text = "Service Details";
        breadcrumbLinkColor = "color-primary-50";
        breadcrumbEndLinkColor = "gray_text";
        arrow_color = "color-primary-50";
    } else if(locationVal.includes("schedule-details/")) {
        h1TextColor = "color-primary-50";
        h1Text = "Class Details";
        breadcrumbLinkColor = "color-secondary-100";
        breadcrumbEndLinkColor = "gray_text";
        arrow_color = "color-primary-50";
    } else if(locationVal === "/news") {
        helmetBgColor = "newsNavBar";
        h1TextColor = "color-primary-50";
        h1Text = "News";
        breadcrumbLinkColor = "color-secondary-100";
        breadcrumbEndLinkColor = "color-neutral-50";
        arrow_color = "color-secondary-100";
    } else if(locationVal === "/catalysts") {
        helmetBgColor = "catalystsNavBar";
        h1TextColor = "white_text";
        h1Text = "Catalysts";
        breadcrumbLinkColor = "white_text";
        breadcrumbEndLinkColor = "white_text";
    } else if(locationVal.includes("catalyst/")) {
        h1TextColor = "white_text";
        helmetBgColor = "catalystDetailsNavBar";
        h1Text = "Catalyst";
        breadcrumbLinkColor = "white_text";
        breadcrumbEndLinkColor = "white_text";
    } else if(locationVal === "/about") {
        helmetBgColor = "aboutNavBar";
        h1TextColor = "color-primary-50";
        h1Text = "About Minded";
        breadcrumbLinkColor = "color-secondary-100";
        breadcrumbEndLinkColor = "color-neutral-50";
        arrow_color = "color-secondary-100";
    } else if(locationVal === "/contact") {
        helmetBgColor = "contactNavBar";
        h1TextColor = "white_text";
        h1Text = "Contact";
        breadcrumbLinkColor = "white_text";
    }

    const helmetDetails = {};

    helmetDetails.helmetBgColor = helmetBgColor;
    helmetDetails.h1Text = h1Text;
    helmetDetails.h1TextColor = h1TextColor;
    helmetDetails.breadcrumbLinkColor = breadcrumbLinkColor;
    helmetDetails.breadcrumbEndLinkColor = breadcrumbEndLinkColor;
    helmetDetails.arrow_color = arrow_color;
    return helmetDetails;
}

const listSeverDaysFromDate = (d, isMobileView = false, isDayView = false) => {
    const dayArray = [];
    const future = new Date(d);
    future.setDate(future.getDate());

    const weekCount = (isMobileView && isDayView === "true") ? 0 : 6;

    var finalDate = future.getFullYear() +'-'+ ((future.getMonth() + 1) < 10 ? '0' : '') + (future.getMonth() + 1) +'-'+ addZeroBefore(future.getDate());
    dayArray.push(finalDate);

    if(!isMobileView || isMobileView && isDayView === "false") {
        const newData = [...Array(weekCount).keys()].map((day, index) => {
            future.setDate(future.getDate() + 1); // add 7 days
            var finalDate = future.getFullYear() +'-'+ ((future.getMonth() + 1) < 10 ? '0' : '') + (future.getMonth() + 1) +'-'+ addZeroBefore(future.getDate());
            
            dayArray.push(finalDate);
        });
    }
    
    return dayArray;    
}

const getWeekDays = (startDate, isMobileView = false, isDayView = false) => {

    var now = typeof(startDate) === 'string' ? new Date(startDate) : startDate ;
    let dayArray = [];

    if(!isMobileView || isDayView === "false") {
        var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));

        dayArray = listSeverDaysFromDate(lastSunday, isMobileView, isDayView);
       
    } else {
        dayArray = listSeverDaysFromDate(now, isMobileView, isDayView);
    }

    return dayArray;
}

const convertDateToString = (epoc = "") => {
    const epocDate = new Date(epoc);

    if(epocDate === "" || !epocDate) return "";

    const d = extractDateFromMoment(epocDate);

    const dates = d.split("-");
    const year = dates[2];
    const month = dates[1];
    const date = dates[0];

    return `${date} OF ${monthNames[month - 1]} ${year}`;
}

const getDateFromEpoc = (epoc = "") => {
    const d = new Date(epoc);
    const date = extractDateFromMoment(d);
    return date;
}

const extractDateFromMoment = (d) => {
    const day   = d.getDate();
    const month = d.getMonth() + 1;
    const year  = d.getFullYear();

    const currentDateValue = addZeroBefore(day) +  "-" + addZeroBefore(month) + "-" + year ;
    return currentDateValue; //in DD-MM-YYYY format
}

const generateDropdownList = (list = []) => {
    if (list === []) {
        const obj = {};
        obj.locationList = [];
        obj.catalystList = [];
        obj.serviceList = [];
        return obj;
    }
    
    const obj = {};

    const nameList = [];
    const locList = [];
    const catList = [];

    const filterNameData =  list.map(item => {
        const obj = {};
        obj.id = item.userId;
        obj.name = item.name;

        nameList.push(obj)
    });

    const filterLocData =  list.map(item => item?.catalystDetails && item?.catalystDetails?.locations?.map(loc => {
        const obj = {};
        obj.id = loc.city;
        obj.name = loc.city;

        const index = locList.findIndex(object => {
            return object.id === obj.id;
        });

        if(index === -1) {
            locList.push(obj)
        }
    }));

    const filterCatData =  list.map(item => item?.catalystDetails && item?.catalystDetails?.serviceCategory?.map(cat => {
        
        const obj = {};

        obj.id = cat.id;
        obj.name = cat.name;

        const index = catList.findIndex(object => {
            return object.id === obj.id;
        });

        if(index === -1) {
            catList.push(obj)
        }
    }));

    obj.catalystList = [...new Set(nameList)];
    obj.serviceList  = [...new Set(catList)];
    obj.locationList = [...new Set(locList)];

    return obj;
}

const generateEventsDropdownList = (list = []) => {
    if (list === []) {
        const obj = {};
        obj.locationList = [];
        obj.catalystList = [];
        obj.serviceList = [];
        return obj;
    }
    
    const obj = {};

    const nameList = [];
    const locList = [];
    const catList = [];

    const filterNameData = list.map(item => {
        if(item?.serviceDetails?.userDetails?.userId) {
            const newObj = {};
            newObj.id = item?.serviceDetails?.userDetails?.userId;
            newObj.name = item?.serviceDetails?.userDetails?.userName;

            nameList.push(newObj);
        }
    });
    //const filterLocData =  list.map(item => item.serviceDetails && locList.push(item?.serviceDetails?.userDetails?.serviceLocation?.city));
    
    const filterLocData = list.map(item => {
        
        if(item?.serviceDetails?.serviceLocation?.city) {
            const newObj = {};
            newObj.id = item?.serviceDetails?.serviceLocation?.city;
            newObj.name = item?.serviceDetails?.serviceLocation?.city;
            if(newObj?.name) {
                locList.push(newObj);
            }
        }
    });

    const filterCatData = list.map(item => {
        
        if(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId) {
            const newObj = {};
            newObj.id = item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId;
            newObj.name = item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryName;
        
            catList.push(newObj);
        }
    });

    obj.catalystList = getSetArray(nameList);
    obj.serviceList  = getSetArray(catList);
    obj.locationList = getSetArray(locList);

    return obj;
}

const getSetArray = (nameList = []) => {
    const filtered = nameList.filter((obj, index) => {
        return index === nameList.findIndex(o => obj.id === o.id);
    });

    return filtered;
}

const generateNewsDropdownList = (list = []) => {

    if (list === []) {
        const obj = {};
        obj.locationList = [];
        obj.catalystList = [];
        obj.serviceList = [];
        return obj;
    }
    
    const obj = {};

    const nameList = [];
    const locList = [];
    const catList = [];

    const filterNameData = list.map(item => item.blog_name && nameList.push(item.blog_name));
    const filterCatData = list.map(item => item.blog_type && catList.push(item.blog_type));
    const filterLocData = list.map(item => item.blog_topic && locList.push(item.blog_topic));

    obj.catalystList = [...new Set(nameList)];
    obj.serviceList  = [...new Set(catList)];
    obj.locationList = [...new Set(locList)];
   
    return obj;
}

const dayWiseScheduleArray = (serviceList = [], currentDate = "") => {
    if(serviceList.length === 0) return [];

    let date_wise_Event_array = serviceList.filter(schedule => getScheduleDate(schedule.scheduleDate) === currentDate);

    date_wise_Event_array = date_wise_Event_array.map((eachSchedule, iinx) => {

        const epoc = new Date().getTime();

        if(eachSchedule?.scheduleDate <= epoc) {
            eachSchedule.isDisabled = true;
        } else {
            eachSchedule.isDisabled = false;
        }
        return eachSchedule;
    });

    return date_wise_Event_array;

    /*const abc = date_wise_Event_array.filter((schedule => service?.schedule?.scheduleInfo.some(eachSchedule => {
        if(getScheduleDate(eachSchedule?.serviceStartDate) === currentDate) {
            const obj = {};

            const stTime = new Date(eachSchedule?.startTime);
          

            console.log(eachSchedule);

            obj.serviceId = service.serviceId;
            obj.author = service?.userDetails?.name;
            obj.scheduleId = eachSchedule?.scheduleId;
            obj.serviceName = service?.serviceName;
            obj.serviceDescription = service?.serviceDescription;
            obj.service_cat_name = service?.serviceCategoryDetails?.serviceCategoryName;
            obj.service_cat_id = service?.serviceCategoryDetails?.serviceCategoryId;
            obj.startTime = eachSchedule?.startTime;
            obj.endTime = eachSchedule?.endTime;
            obj.duration = eachSchedule?.duration;
            obj.serviceLocation = service?.schedule?.serviceLocation;
            obj.serviceCategoryName = service?.serviceCategoryDetails.serviceCategoryName;

            console.log(obj);
            
            date_wise_array.push(obj);
        }
        })
    ));
    */
}

const getScheduleDate = (epoc = 0) => {
    const d = new Date(epoc);
    const FormattedDate = extractDateFromMoment(d);
    const finalFormmattedDate =  FormattedDate.split("-").reverse().join("-");

    return finalFormmattedDate;
}

const getTypeFromActiveTab = (activeTab = 0) => {
    let type = "";

    if(activeTab === 0) {
        type = "CLASS";
    } else if(activeTab === 1) {
        type = "PRIVATE";
    } else {
        type = "EVENT";
    }

    return type;
}

const getCatalystDetailsServiceTypeFromActiveTab = (activeTab = 0) => {
    let type = "";

    if(activeTab === 0) {
        type = "";
    }
    else if(activeTab === 1) {
        type = "CLASS";
    } else if(activeTab === 2) {
        type = "PRIVATE";
    } else {
        type = "EVENT";
    }

    return type;
}

const getTicketTypeFromActiveTab = (activeTab = 0) => {
    let type = "";

    if(activeTab === 0) {
        type = "ticket";
    } else if(activeTab === 1) {
        type = "package";
    } else {
        type = "plan";
    }

    return type;
}

const isEmptyJson = (jsonToTest  = {}) => {
    return (JSON.stringify({}) === JSON.stringify(jsonToTest))
}

const isEmailValid = (email = "") => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

const isTimeValid = (list = []) => {
    if(list && list.length === 0) return false;

    let isValid = true;

    list.map((eachSc, index) => {
        eachSc?.dayWiseTimeList?.map((eachtime) => {
            if(!isRightTime(eachtime)) {
                isValid = false;
            }
        })
    });

    return isValid;
}

const isRightTime = (eachtime = {}) => {
    const {startTime = "", endTime = ""} = eachtime;

    if(startTime === "" || endTime === "") return false;

    const from_time = startTime;
    const to_time = endTime;

    if(from_time === "" || to_time === "") return false;
    var from = Date.parse('01/01/2011 '+ from_time);
    var to = Date.parse('01/01/2011 '+ to_time);

    if (from > to) {
       console.log ("From greater than To!");
       return false;
    } else {
       console.log ("To greater than from!")
       return true;
    }
}

const getBoardingHeaderText = (step = 0, role = "customer") => {
    let titleVal = "Sign up";

    if(role === "customer" || role === "admin") {
        titleVal = User_OnBoard_Header_Array[step - 1];
    } else if(role === "catalyst") {
        titleVal = Catalyst_OnBoard_Header_Array[step - 1];
    }

    return titleVal;
}

const getServiceHeaderText = (step = 0, type = "EVENT") => {
    let titleVal = "Sign up";

    if(type === "EVENT") {
        titleVal = Event_Header_Array[step - 1];
    } else if(type === "CLASS") {
        titleVal = Class_Header_Array[step - 1];
    } else if(type === "PRIVATE") {
        titleVal = Private_Header_Array[step - 1];
    }

    return titleVal;
}

const getBoardingCTAText = (step = 0, role = "customer", isJump =  false) => {
    let text = "Next";

    if(role === "customer" || role === "admin") {
        switch(step) {
            case 3:
                text = "Submit";
                break;
            case 4:
                text = "Back To Dashboard";
                break;
            default : break;
        }
        return text;
    }

    else if(role === "catalyst") {
        switch(step) {
            case 4:
            if(isJump) {
               text = "Publish";
            }
            break;
            case 6:
                text = "Publish";
                break;
            case 7:
                text = "Done";
                break;
            default : break;
        }
    }

    return text;
}

const getServiceCTAText = (step = 0, type = "EVENT") => {
    let text = "Next";

    if(type === "EVENT") {
        switch(step) {
            case 4:
                text = "Publish";
                break;
            case 5:
                text = "Back To Dashboard";
                break;
            default : break;
        }
        return text;
    } else if(type === "CLASS") {
        switch(step) {
            case 3:
                text = "Publish";
                break;
            case 4:
                text = "Back To Dashboard";
                break;
             default : break;
        }
        return text;
    } else if(type === "PRIVATE") {
        switch(step) {
            case 3:
                text = "Publish";
                break;
            case 4:
                text = "Back To Dashboard";
                break;
            default : break;
        }
        return text;
    }

    return text;
}

const isShowBackBtn = (step = 0, role = "customer", page = "ON_BOARDING") => {
    if(page === "ON_BOARDING") {
        if(role === "customer") {
          if(step === 2 || step === 3) {
            return true;
          }
        } else if(role === "admin") {
            if(step === 3) {
            return true;
          }
        } else if(role === "catalyst") {
          if(step === 2 || step === 3 || step === 4 || step === 5 || step === 6) {
            return true;
          }
          return false;
        }
    }

    return false;
}

const getMinimumTicketPrice = (service = {}) => {
    const packageDetails = service?.servicePriceOptions;
    let minPrice = 0;

    if(Array.isArray(packageDetails)) {
        packageDetails.map((pck, index) => {

            if(pck?.pricingType === "ticket") {
               pck?.tickets?.ticketList && pck?.tickets?.ticketList.map((ticketInfo, index) => {
                    let price = parseFloat(ticketInfo.packagePrice);

                    if(minPrice === 0) minPrice = price;

                    else if(price < minPrice) {
                        minPrice = ticketInfo.packagePrice;
                    }
                }); 
            } else if(pck?.pricingType === "package") {
                pck.packages.map((eachPackage, inx) => {
                    let price = parseFloat(eachPackage.packagePrice);

                    if(minPrice === 0) minPrice = price;

                    else if(price < minPrice) {
                        minPrice = eachPackage.packagePrice;
                    }
                });
            }
                
        });
    }

    if(minPrice === 0) return "Free";

    return `${minPrice} EUR`;
}

const getBookingQuantityValue = (bookingDetails = {}, ticket = {}) => {
    const filterData = bookingDetails?.booking_details?.filter((item, index) => item.id === ticket?.id);
    const quantityVal = filterData && filterData?.length > 0 ? filterData[0].quantity : "";
    
    return quantityVal;
}

const endAfterStart = (start, end) => {
    var startDate = new Date(start);
    var endDate   = new Date(end);

    return endDate.getTime() >= startDate.getTime();
}

const timeRangeValidation = (startTime = "", endTime = "") => {
    var fromTokens = startTime.split(":");
    var toTokens = endTime.split(":");
    return (fromTokens[0] < toTokens[0] || (fromTokens[0] === toTokens[0] && fromTokens[1] < toTokens[1]));
}

const covertToLower = (role = "") => {
    return role && role.toLowerCase();
}

const setCookie = (cookiename, usrin) => {
    Cookie.set(cookiename, usrin, {
        expires: 1,
        secure: true,
        sameSite: 'strict',
        path: '/'
    });
}

const getCookie = (cookiename) => {
    return Cookie.get(cookiename);
}

const removeCookie = (cookiename) => {
    Cookie.remove(cookiename);
}

const getAuthenticateHeader = () => {
   // const token = getCookie("token");
   // const finaToken = `Bearer ${token}`;
    
    const myHeaders = new Headers({
        crossDomain: true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': "GET, PUT, POST, DELETE, HEAD, OPTIONS",
        /*'Authorization': finaToken*/
    });

    return myHeaders;
}

const getTimeDetails = (time) => {
    const stTime = new Date(time);
    const obj = {};

    obj.stHrs = addZeroBefore(stTime.getUTCHours()); // Hours
    obj.stmins = addZeroBefore(stTime.getUTCMinutes()); //Mins

    return obj;
}

const getEpocFromMoment = (val) => {
   return val.getTime();
}

const getTimeFromEpoc = (epoc = 0, tz = "Pacific/Auckland") => {
    // Example epoch timestamp in seconds
    const epochTime = epoc;

    // Convert epoch time to milliseconds
    const date = new Date(epochTime);

    // Specify the options for formatting, including the time zone
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,  // Use 24-hour format
        timeZone: tz || "Pacific/Auckland"
    }

    // Format the date and time using Intl.DateTimeFormat
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedTime = formatter.format(date);
    return formattedTime;

}

const getEpocFromMomentStartDate = (val) => {
    if(val === "") return null;
    val.setHours(0,0,0,0);

    const epocTIme =  val.getTime();
  
    return epocTIme;
}

const isEmptyStringArray = (array = []) => {
    let isReturn = true;
    for(var i=0; i<array.length; i++) { 
        if(array[i] !== "" && array[i] !== null) {
            isReturn = false;
            return isReturn;
        }
    }

    return isReturn;
}

const ConvertDaysArray = (service = {}, serviceType = "CLASS") => {

    let newArray = [];
    if(serviceType === "CLASS" || (serviceType === "EVENT" && service?.serviceScheduleDetails?.serviceScheduleType === "recurring")) {
        const array = service?.serviceScheduleDetails?.serviceScheduleTypeConfig;
        newArray = array.map(item => (item && (item?.charAt(0).toUpperCase() + item?.slice(1))).substr(0,3));
    } else if(serviceType === "PRIVATE") {
        const array = service?.serviceScheduleDetails?.serviceScheduleTypeConfig?.map(item => item.day);
        newArray = array.map(item => (item && (item?.charAt(0)?.toUpperCase() + item?.slice(1)))?.substr(0,3));
    } else if(serviceType === "EVENT" && service?.serviceScheduleDetails?.serviceScheduleType === "single") {
        var d = new Date(service?.serviceStartDate);
        var dayName = dayNames[d.getDay()].toLowerCase();
        const formattedDayName = dayName && (dayName?.charAt(0).toUpperCase() + dayName?.slice(1).substr(0,2));
        newArray.push(formattedDayName);
    }
    return newArray;
}

const ConvertPrivateTimesArray = (service = {}, serviceType = "PRIVATE") => {
    const list = [];
    
    if(serviceType === "PRIVATE") {
        const listArray = service?.serviceScheduleDetails?.serviceScheduleTypeConfig?.map((item, index) => {
            item?.dayWiseTimeList?.map((eachTimeList, idx) => list.push(eachTimeList))
        })
    }

    let val = "";

    if(list.length === 1) {
        val = CalculateDuration(list[0]?.startTime, list[0]?.endTime);
    } else {
        val = "Multiple Times"
    }

    return val; // return "Multiple Times";
}

const CalculateDuration = (startTime = "", endTime = "") => {

    if(!startTime || !endTime) return "Multiple Times";

    const st = moment(startTime, "HH:mm");
    const et = moment(endTime, "HH:mm");
    const duration = moment.duration(et.diff(st));

    const hours = parseInt(duration.asHours());
    const mins = parseInt(duration.asMinutes()) - (hours * 60);
    
    if(hours === 0) {
        return Math.abs(mins) === 1 ? `${mins} min` : `${mins} mins`
    } else if(mins === 0) {
        return Math.abs(hours) === 1 ? `${hours} Hr` : `${hours} Hrs`
    }

    return `${hours} Hrs - ${mins} mins`;
}

const getDurationDifferentFromCurrent = (timestamp = "") => {
    //const timestamp = "2024-02-24T10:05:14.052+00:00";
    if (!timestamp || timestamp === "") return "-";
    const dateOnly = new Date(timestamp).toISOString().split('T')[0];
   const diff = moment(dateOnly, "YYYY-MM-DD").fromNow();
  
   return diff;
}

const getLocationLink = (serviceLocation = {}) => {
    let link = "#";
    const {lat = "", lng = ""} = serviceLocation && serviceLocation?.coordinate || {};
    
    if(lat !== "" && lng !== "") {
        link = `https://events.wixapps.net/events-server/html/google-map?locale=en&lat=${lat}&lng=${lng}&instance=nLtyeAXlu3d8fgFquW3fs8WoskX3eU10A6DoBBFawjo.eyJpbnN0YW5jZUlkIjoiNzU3NTVkYTYtYTFiNy00NTYyLWI3YTQtNjlkZGI1Nzg0ZTM4IiwiYXBwRGVmSWQiOiIxNDA2MDNhZC1hZjhkLTg0YTUtMmM4MC1hMGY2MGNiNDczNTEiLCJtZXRhU2l0ZUlkIjoiZjQ5Y2Y4NTYtZGE0Ny00MDdiLTgzMjYtNTk4ODI3YTFkZGIwIiwic2lnbkRhdGUiOiIyMDI0LTAzLTEzVDE0OjAxOjQ4LjEwN1oiLCJkZW1vTW9kZSI6ZmFsc2UsIm9yaWdpbkluc3RhbmNlSWQiOiI2NmUxMTAzZC04ZmU2LTQ0MDMtYWM1Ni03MTU3MDMzNzlmNDUiLCJhaWQiOiJhYTFkNzJiMS0yMzZlLTQ4YTYtODQ0Mi1hMDEzYzRmZTRlZWEiLCJiaVRva2VuIjoiODFlOWE1ZjAtN2JmMC0wNTE5LTM0ODItMzA1NTkyZDk5Mzg4Iiwic2l0ZU93bmVySWQiOiIwYzEwNGFlOC0xYTY4LTRhMDQtYTg4Mi1jYTg4YjgxMDI1YmIifQ`;
    }
    
    return link;
};

const isToday = (sch, currentDateEpoc) => {
    return (getScheduleDate(sch?.scheduleDate) === getScheduleDate(currentDateEpoc));
}

const getSelectedDateSchedules = (allSchedules = [], currentDateEpoc = new Date().getTime()) => {
    const list = allSchedules.filter((sch, index) => isToday(sch, currentDateEpoc));
    return list;
}

const truncateString = (inputString) => {

    if(!inputString || inputString === "") return "Guest";
    let truncatedString = "";
    let words = inputString.split(" ");

    for (let i = 0; i < words.length; i++) {
        if(i === 0) {
            truncatedString += words[i];
        } else {
            truncatedString += " " + words[i][0];
        } 
    }
    return truncatedString;
}

const groupById = (array = []) => {
    const groupedObjects = {};

    array.forEach(obj => {
        const id = obj.purchaseId; /*ticketPlanPackageTypeId*/

        if (!groupedObjects[id]) {
            groupedObjects[id] = [];
            
        }
        groupedObjects[id].push(obj);
    });

    return Object.values(groupedObjects);
}

const addMonths = (date, months) => {
    const d = new Date(date);
    const newMonth = d.getMonth() + months;
    d.setMonth(newMonth);
    return d;
}

const calculateEpochTimeInThreeMonths = (addMonth = 3) => {
    // Get current date
    const currentDate = new Date();

    // Add 3 months to the current date
    const futureDate = addMonths(currentDate, addMonth);

    // Get the epoch time in milliseconds for the future date
    const futureEpochTime = futureDate.getTime();

    return futureEpochTime;
}

const getEachTicketTotalServiceFee = (item = {}) => {
    let serviceFee = 0;

    /*if(item.pricingType === "ticket") {
        item.tickets.ticketList.map((eachTicket, i) => {
         
          if(eachTicket.serviceFeeType === "%") {
            serviceFee += ((parseFloat(eachTicket.packagePrice) *  eachTicket.serviceFee * eachTicket.quantity)/100);
          } else {
            serviceFee += (parseFloat(eachTicket.packagePrice) * eachTicket.serviceFee * eachTicket.quantity);
          }
        })
    } else if(item.pricingType === "package") {
       
        if(item.serviceFeeType === "%") {
            serviceFee += ((parseFloat(item.packagePrice) *  item.serviceFee * item.quantity)/100);
        } else {
          serviceFee += (parseFloat(item.packagePrice) * item.serviceFee * item.quantity)
        }
    } else if(item.pricingType === "plan") {
        
        if(item.serviceFeeType === "%") {
            serviceFee += ((parseFloat(item.planPrice) *  item.serviceFee * item.quantity)/100);
        } else {
          serviceFee += (parseFloat(item.planPrice) * item.serviceFee * item.quantity)
        }
    }*/

    //Amit : Hiding serviceFee

    return serviceFee;
}

const getTicketPrice = (eachTicket) => {
    return (parseFloat(eachTicket.packagePrice) * eachTicket.quantity);
}

const getTicketServiceFee = (eachTicket) => {
    let serviceFee = 0;

    /*if(eachTicket.serviceFeeType === "%") {
        serviceFee = ((parseFloat(eachTicket.packagePrice) *  parseFloat(eachTicket.serviceFee) * eachTicket.quantity)/100);
    } else {
        serviceFee = (parseFloat(eachTicket.packagePrice) * parseFloat(eachTicket.serviceFee) * eachTicket.quantity);
    }*/

    //Amit : Hiding serviceFee
    return serviceFee;
}

const getEachTicketTotalPrice = (item = {}) => {
    let price = 0;

    if(item.pricingType === "ticket") {
        item.tickets.ticketList.map((eachTicket, i) => {
          price += (parseFloat(eachTicket.packagePrice) * eachTicket.quantity);
        });
    } else if(item.pricingType === "package") {
        price = (parseFloat(item.packagePrice) * item.quantity);
    } else if(item.pricingType === "plan") {
        price = (parseFloat(item.planPrice) * item.quantity);
    }

    return price;
}

const epochToDate = (epoch) => {
  return new Date(epoch * 1000); // Multiply by 1000 to convert seconds to milliseconds
};

const getMonthIndex = (timestamp1, timestamp2) => {
  const date1 = epochToDate(timestamp1);
  const date2 = epochToDate(timestamp2);
  const differenceInTime = date1.getTime() - date2.getTime(); // Difference in milliseconds
  const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days

  return Math.abs(differenceInDays) % 30;
};

const getFormmatedServiceName = (serviceType = "") => {
    switch(serviceType) {
        case "CLASS" : 
            return "Class";
        case "EVENT" : 
            return "Event";
        case "PRIVATE" :
            return "1:1 Session";
        default: 
            break;
    }

    return serviceType;
}

const getQuantity = (list = [], id = "", type = "") => {

    if(list === []) return 0;
    if(type === "ticket") {
        const filterItem = list.filter((item) => item.pricingType === type && item.tickets.id === id);

        if(filterItem && filterItem[0]) {
            const  foundItem = filterItem[0];
            return foundItem?.tickets?.ticketList[0]?.quantity || 0
        }
    } else if(type === "package" || type === "plan") {
        const filterItem = list.filter((item) => item.pricingType === type && item.id === id);
        if(filterItem && filterItem[0]) {
            const  foundItem = filterItem[0];
            return foundItem?.id || 0
        }
    }

    return 0; 
}

const getCurrentMonthFirstDayEpoc = () => {
    let currentDate = new Date();

    // Set the date to the first day of the current month
    currentDate.setDate(1);

    // Get the epoch time (Unix timestamp) in milliseconds
    let epochTime = currentDate.getTime(); // Divide by 1000 to convert to seconds

    return epochTime;
}

const getEndEpochAfterGivenDate = (days = 0) => {
    const currentDate = new Date();

    // Add 14 days to the current date
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + days);

    // Get the epoch timestamp (in milliseconds) of the future date
    const epochTimestamp = futureDate.getTime();
    return epochTimestamp;
}

const isScheduleExpired = (epoc = 0) => {
    const currentEpoc = new Date().getTime();
    return  currentEpoc > epoc;
}

const evaluateDate = (givenDate = 0) => {
    // Ensure the input is a Date object
    let text = "";
    
    // Get today's date and normalize time to midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Create Date objects for tomorrow and in 30 days from today
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const in30Days = new Date(today);
    in30Days.setDate(today.getDate() + 30);

    // Normalize given date to midnight for accurate comparison
    const normalizedGivenDate = new Date(givenDate);
    normalizedGivenDate.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    const timeDifference = normalizedGivenDate - today;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (normalizedGivenDate < today) {
        text = "Your Subscription has expired.";
    } else if (normalizedGivenDate.toDateString() === today.toDateString()) {
        text = "Your Subscription is going to exipre today.";
    } else if (normalizedGivenDate.toDateString() === tomorrow.toDateString()) {
        text = "Your Subscription is going to exipre tomorrow.";
    } else if (normalizedGivenDate <= in30Days) {
        text = `Your Subscription is going exipre in ${daysDifference} days.`;
    } else {
        text = "";
    }

    return text;
}

export {
    addZeroBefore,
    CalculateDuration,
    calculateEpochTimeInThreeMonths,
    covertToLower,
    ConvertDaysArray,
    convertDateToString,
    ConvertPrivateTimesArray,
    dayWiseScheduleArray,
    evaluateDate,
    extractDateFromMoment,
    endAfterStart,
    groupById,
    getFormmatedServiceName,
    getDateFromEpoc,
    getMonthIndex,
    getCurrentMonthText,
    getFormattedCurrentMonthFirstDate,
    getFormattedCurrentMonthTodayDate,
    getCurrentMonthFirstDayEpoc,
    getSelectedDateSchedules,
    getDurationDifferentFromCurrent,
    getScheduleDate,
    getTimeFromEpoc,
    getLocationLink,
    getCookie,
    getEpocFromMoment,
    getEpocFromMomentStartDate,
    getEndEpochAfterGivenDate,
    getAuthenticateHeader,
    getMinimumTicketPrice,
    getFormattedDate,
    getTicketPrice,
    getTicketServiceFee,
    getEachTicketTotalServiceFee,
    getEachTicketTotalPrice,
    getTimeDetails,
    getBookingQuantityValue,
    getTicketTypeFromActiveTab,
    generateDropdownList,
    generateNewsDropdownList,
    generateEventsDropdownList,
    getBoardingCTAText,
    getBoardingHeaderText,
    getServiceHeaderText,
    getServiceCTAText,
    getCatalystDetailsServiceTypeFromActiveTab,
    getTypeFromActiveTab,
    getMonthText,
    getMonthDayText,
    getNavDetails,
    getWeekDays,
    getHelmetDetails,
    getQuantity,
    isEmailValid,
    isScheduleExpired,
    isShowBackBtn,
    isTimeValid,
    isEmptyJson,
    isEmptyStringArray,
    listSeverDaysFromDate,
    monthNames,
    removeCookie,
    setCookie,
    timeRangeValidation,
    truncateString
};