import {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import FloatSelectView from '../../../components/floatSelectView/FloatSelectView';
import Input from '../../../components/input/Input';
import {getTicketServiceFee, getTicketPrice, getTimeFromEpoc} from '../../../utils/util';
import {CountryList, getTimeZoneKey} from '../../../utils/DefaultStates';
import {InitialPaymentBillingAddress} from '../../../utils/DefaultInitialValues';

const PaymentView = forwardRef((props, ref) => {
  const {
    bookingList = [],
    scheduleDetails = {},
    overallPrice = 0,
    bookingNavigationStepper = -1,
    onUpdateBillingAddress
  } = props;

  const [billingAddress, setBillingAddress] = useState(InitialPaymentBillingAddress);
  const [billingError, setBillingErrors] = useState({});

  const [isTermsChecked, setTermsChecked] = useState(false);

  const {
    streetError = false,
    cityError = false,
    postcodeError = false,
    countryError = false,
    termsError = false
  } = billingError || {};

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  const onChangeBillingAddress = (e) => {
    setBillingAddress({...billingAddress, [e.target.name]: e.target.value});
  }

  useImperativeHandle(ref, () => ({
  
    validatePaymentAddress() {
      let isStreetError = false;
      let isCityError = false;
      let isPostcodeError = false;
      let isCountryError = false;
      let isTermsError = false;

      let isFormValid = true;

      if(street1 === "") {
        isStreetError = true;
        isFormValid = false;
      }
      if(city === "") {
        isCityError = true;
        isFormValid = false;
      }
      if(postcode === "") {
        isPostcodeError = true;
        isFormValid = false;
      }
      if(country === "") {
        isCountryError = true;
        isFormValid = false;
      }

      if(!isTermsChecked) {
        isTermsError = true;
        isFormValid = false;
      }

      billingAddress.terms = isTermsChecked;

      setBillingErrors({
        ...billingError,
        streetError: isStreetError,
        cityError: isCityError,
        postcodeError: isPostcodeError,
        countryError: isCountryError,
        termsError: isTermsError
      });

      if(isFormValid) {
        onUpdateBillingAddress(billingAddress);
      }

      return isFormValid;
    }
  }));

  const {
    scheduleStartTime,
    scheduleEndTime,
    serviceDetails = {}
  } = scheduleDetails || {};

  const {
    serviceName = "",
    serviceCategoryDetails = {},
    userDetails = {},
    serviceLocation = {},
    startTime = "",
    endTime = "",
    serviceTimezone = "",
    serviceType = ""
  } = serviceDetails || {};

  const {
    serviceCategoryName = ""
  } = serviceCategoryDetails || {};

  const {
    street1 = "",
    city = "",
    postcode = "",
    country = ""
  } = billingAddress || {};

  return (
    <div className="dialog-body">
      <div className="container dialog_padding">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="payment">
              <div className="payment_left_view">
                <div className="payment_card_container">
                  <h4 className="color-primary-50 heading-4">Add card details</h4>
                  <div className="body-R color-primary-50 py-3">
                    We currently support the bank cards from VISA, Mastercard, American Express, and UnionPay.
                  </div>
                </div>
              
                <div className="payment_card_container">
                  <div className="color-primary-50 subtitle-B">Address</div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="street1" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "street no" 
                          name="street1" 
                          classVal="w-100"
                          value={street1}
                          onInputChange = {e => onChangeBillingAddress(e)}
                        /> 
                        {streetError && <div className="text-danger pt-1">! Street is a required field</div>} 
                      </div>
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="postcode" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "Zip/postal code" 
                          name="postcode" 
                          classVal="w-100"
                          value={postcode}
                          onInputChange = {e => onChangeBillingAddress(e)}
                        /> 
                        {postcodeError && <div className="text-danger pt-1">! Postal code is a required field</div>} 
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="city" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "City" 
                          name="city" 
                          classVal="w-100"
                          value={city}
                          onInputChange = {e => onChangeBillingAddress(e)}
                        />
                        {cityError && <div className="text-danger pt-1">! City is a required field</div>}   
                      </div>
                      <div className="col-lg-6 my-2">
                        <FloatSelectView
                          name = "country"
                          label = "Country"
                          list = {CountryList}
                          value = {country}
                          onInputChange = {e => onChangeBillingAddress(e)} 
                  
                        />
                        {countryError && <div className="text-danger pt-1">! Country is a required field</div>}  
                      </div>
                    </div>
                  </div>

                  <div className="color-primary-50 mt-3">
                  <div className="d-flex mt-3">
                    <input type="checkbox" id="agree" value={isTermsChecked} onChange = {e => setTermsChecked(!isTermsChecked)} checked = {isTermsChecked} />
                    <label className="ps-2 color-primary-50" for="agree">
                      I accept the terms of use and &nbsp; 
                      <a href="https://www.theminded.club/_files/ugd/0c104a_e818e156abc641c386fec273c5c3dd97.pdf" target="_blank" className="color-primary-50 text-underlined"><u>Privacy policy</u></a>
                    </label>
                  </div>
                  {termsError && <div className="text-danger pt-1">! Please accept the terms and policy</div>}  
                </div>
                </div>
            </div>

            <div className="payment_right_view">
              <div className="row p-4 rounded-4 coupon w-100">
                <div className="d-flex justify-content-between">
                  <Input 
                    type="text" 
                    id="coupon" 
                    placeHolder = "xxxx-xxxx" 
                    label = "coupon" 
                    name="coupon" 
                    classVal="w-100"
                    value=""
                    isDisabled={true}
                    onInputChange = {e => onChangeBillingAddress(e)}
                  /> 
                  <button className="btn btn-outline-warning ms-3" disabled>Apply</button>
                </div>
              </div>

              <div className="row px-4 rounded-4 coupon w-100">
                <div className="col-lg-12">
                  <div className="py-5">
                    <h4 className="pb-3 heading-4 color-primary-50">Overview</h4>
                    <p className="subtitle-R color-primary-50">{serviceName} | {(serviceLocation?.city && serviceLocation?.city !== "") ? serviceLocation?.city : "Online"} | by {userDetails?.userName}</p>
                    
                    {(bookingNavigationStepper === -1) && <div className="py-3">
                       
                      {serviceType !== "PRIVATE" && <div className="body-R color-primary-50">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
                      {serviceType === "PRIVATE" && <div className="body-R color-primary-50">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}

                      <div className="body-R color-primary-50">{serviceCategoryName}</div>
                    </div>}

                    {bookingList.map((eachBooking, index) => {
                      return (
                        <>
                          {eachBooking?.pricingType === "ticket" && <div className="py-1">          
                            {
                              eachBooking?.tickets?.ticketList.map((eachTicket, index) => {
                                return(
                                  <div className="d-flex justify-content-between align-items-center">
                                    {eachTicket?.quantity !== 0 && <div className="body-R color-primary-50">{eachBooking?.tickets.ticketName}  x  {eachTicket.quantity}</div>}
                                    {eachTicket?.quantity !== 0 && <div className="body-B color-primary-50">{(getTicketPrice(eachTicket)) + getTicketServiceFee(eachTicket)} EUR</div>}
                                  </div>
                                )
                              })
                            }

                            </div>
                          }
                          {eachBooking?.pricingType === "package" && <div className="d-flex justify-content-between py-1">
                            <div className="body-R color-primary-50">{eachBooking?.packageName}  x  {eachBooking?.quantity}</div>
                            <div className="body-B color-primary-50">{(eachBooking?.packagePrice * eachBooking?.quantity)} EUR</div>
                          </div>
                          }
                          {eachBooking?.pricingType === "plan" && <div className="d-flex justify-content-between py-1">
                            <div className="body-R color-primary-50">{eachBooking?.planName}  x  {eachBooking?.quantity}</div>
                            <div className="body-B color-primary-50">{(eachBooking?.planPrice * eachBooking?.quantity)} EUR</div>
                          </div>
                          }
                          {bookingList.length !== index && <hr />}
                        </>
                      )
                    })}

                    <div className="d-flex justify-content-between py-3">
                      <div className="body-R color-primary-50">Total</div>
                      <div className="body-B color-primary-50">{overallPrice} EUR</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>      
  )
});

export default PaymentView;