import React, {useState, useEffect} from 'react';
import MyTimer from '../timer/Mytimer';

const PaymentDetailsView = (props) => {

	const {service_name = "-", serviceCategory = "-", author = "-", startTime = "", endTime = "", plan = "Starter", quantity = 0,  total = 0} = props;
	const [timer, setTimer] = useState(new Date());
  const [isTimeExpire, setTimeExpire] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 119);

  const onHandleTimeExpire = () => {
    setTimeExpire(true);
  }

  return (
    <>
  		<div className="row px-4 rounded-4 coupon w-100">
        <div className="col-lg-12">
          <div className="py-5">
            <h4 className="color-primary-50 heading-4">Overview</h4>
            <p className="subtitle-R color-primary-50">{plan}</p>
            
            <div className="py-3">
              <div className="body-R color-primary-50">{startTime} – {endTime}</div>
              <div className="body-R color-primary-50">{serviceCategory}</div>
            </div>

            <div className="d-flex justify-content-between py-3">
              <div className="body-R color-primary-50">{plan}  x  {quantity + 1}</div>
              <div className="body-B color-primary-50">{total} EUR</div>
            </div>

            <hr />

            <div className="d-flex justify-content-between py-3">
              <div className="body-R color-primary-50">Total</div>
              <div className="body-B color-primary-50">{total} EUR</div>
            </div>
          </div>
        </div>
      </div>
      
      {/*!isTimeExpire && 
        <div className="row px-4 rounded-3 coupon color-peach_bg w-100 p-3">
          <div className="col-lg-12">
            <div className="body-R color-primary-50 text-center">
              Time remaining <MyTimer expiryTimestamp={time} onExpire={onHandleTimeExpire} />
            </div>
          </div>
        </div>
      */}
    </>
	)
}

export default PaymentDetailsView;