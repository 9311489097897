import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {getDateFromEpoc} from '../../utils/util';
import img_placeholder from '../../assets/img_placeholder.png';

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import './slider.css';
// import required modules
import { Scrollbar } from "swiper/modules";

const ProgressSlider =  (props) => {

  const {list = [], onCardClick } = props;

  return (
    <>
      <Swiper
        slidesPerView={1.2}
        spaceBetween={30}
        scrollbar={{
          hide: false
        }}
        modules={[Scrollbar]}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          }
      }}
      >
        {list.map((item, index) => {
          
          const {serviceName = ""} = item || {};
          
          return (
            <SwiperSlide onClick = {() => onCardClick(item)}>
              <div className="slide_container">
                <div className="slide_img_container">
                  {item && <img src = {item?.serviceImg && item?.serviceImg[0] || img_placeholder} className="w-100 m-auto h-100" />}
                </div>

                <div className="pb-5 text-center w-100">
                  <div className="body-CAP color-primary-20">{getDateFromEpoc(item?.serviceStartDate)}</div>
                  <h5 className="heading-5 color-primary-50">{serviceName}</h5> 
                </div>

              </div>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  );
}

export default ProgressSlider;
