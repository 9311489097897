import Input from '../../../../components/input/Input';
import Textarea from '../../../../components/textarea/Textarea';
import FloatSelectView from '../../../../components/floatSelectView/FloatSelectView';
import {getFormmatedServiceName} from '../../../../utils/util';
import {Category_List} from '../../../../utils/DefaultInitialValues';
import UploadImage from '../../../../components/uploadimg/UploadImage';
import brown_cancel from '../../../../assets/brown_cancel.png';
import img_placeholder from '../../../../assets/img_placeholder.png';

const UpdateServiceDetails = (props) => {
	const { serviceDetails = {}, serviceErrors = {}, onUpdateServiceInfo, onUpdateCategory, serviceImageList = [], onServiceImgUpdate} = props;

  const {
    serviceName = "",
    serviceDescription = "",
    serviceType = "",
  } = serviceDetails || {};

  const {
    serviceCategoryId = ""
  } = serviceDetails?.serviceCategoryDetails || {};

  const {
    course_name_error = false,
    course_description_error = false,
    course_category_error = false
  } = serviceErrors || {};

  const updateCategory = (e) => {
    const {value} = e.target || {};

    const filterItem = Category_List.filter((item,index) => item.id === value);

    if(filterItem && filterItem.length) {
      const selectedOptionName = filterItem[0].name;
      onUpdateCategory(e, selectedOptionName);
    }
  }

  const onDeleteImg = (delInx) => {
    const newEventImgList = serviceImageList?.filter((item, index) => index !== delInx);
    onServiceImgUpdate(newEventImgList);
  }

  const uploadPicture = (e) => {
    const newPic = e.target.files[0];
    //const pic = URL.createObjectURL(newPic);

    const newArray = [...serviceImageList];

    newArray.push(newPic);
    onServiceImgUpdate(newArray);
  }

	return (
		<>
      <div className="container dialog_padding">
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 border_50">
              <h5 className="heading-5 color-primary-50">1.1 General</h5>
              <div className="w-100">
                <Input
                  name = "serviceName"
                  label = {`${getFormmatedServiceName(serviceType)} name`}
                  placeHolder = "xxx" 
                  value = {serviceName}
                  onInputChange = {(e) => onUpdateServiceInfo(e)} 
                />
                {course_name_error && <div className="text-danger pt-1">! Session name is a required field</div>}
              </div>

              <div className="mb-2 w-100">
                <label for="serviceDescription" className="color-primary-10 mb-2">Description</label>
                <Textarea 
                  id="serviceDescription" 
                  name="serviceDescription" 
                  value={serviceDescription} 
                  className="w-100" 
                  placeholder="write description with max 3000 words." 
                  onInputChange={(e) => onUpdateServiceInfo(e)} 
                />
                {course_description_error && <div className="text-danger pt-1">! Description is a required field</div>}
              </div>

              <div className="mb-2 w-100">
                <FloatSelectView
                  name="serviceCategoryId"
                  label = "Category"
                  list = {Category_List}
                  value={serviceCategoryId}
                  labelFieldName = "name"
                  valFieldName = "id"
                  onInputChange = {e => updateCategory(e)} 
                />
                {course_category_error && <div className="text-danger pt-1">! Category is a required field</div>}
              </div>
            </div>

            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50">
            
              <h5 className="heading-5 color-primary-50">1.2 Image</h5>
                
              <UploadImage 
                pic = {img_placeholder} 
                uploadPicture = {(e) => uploadPicture(e)} 
                serviceName = {serviceType} 
                isDisabled = {serviceImageList?.length >= 5}
              />
              
              {serviceImageList?.length > 0 && 
                <div className="img-container">
                  {serviceImageList.map((img, index) => {
                    
                    return (
                      <div className="img-wrap">
                        <button className="image_close_icon ax-button" onClick={() => onDeleteImg(index)}>
                          <img src={brown_cancel} style={{width: '20px', height: '20px'}} alt = "" />
                        </button>
                        <img src = {(typeof(img)) !== 'string' ? URL.createObjectURL(img) : img} alt = "" />
                      </div>
                    )
                  })}
                </div>
              }

              <h5 className="w-100 heading-5 color-primary-50">{serviceName !== "" ? "" : "Profile"} Image guidelines</h5>
        
              <div className="w-100 upload_img_container py-2">
                <ul className="width-50 body-R color-primary-20">
                  <li>At least 250x250 pixels</li>
                  <li>JPG, PNG formats only</li>
                  <li>Maximum size of 2MB</li>
                </ul>
               
                <ul className="width-50 body-R color-primary-20">
                  <li>Do not make photo public without explicit consent</li>
                  <li>Show eyes and face clearly</li>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
	)
}

export default UpdateServiceDetails;