import avatar from '../../../../../../../assets/avatar.png';

const OverView = (props) => {
	const {userDetails = {}} = props;

	const {
		role = "",
		email = "",
		status = "pending",
		name = "",
		phone = "",
		address = {}
	} = userDetails || {};

	const {
		apartment = "",
		city = "",
		country = "",
		pincode = "",
		state = "",
		street = ""
	} = address || {};

	const {
	    profilePic = "",
	    publicName = "",
	    aboutMe = "",
	    companyDetails = {},
	    socialMediaHandles = [],
	    interestCategories = [],
	    languageSpeak = []
  	} =  userDetails?.profileDescription || {};

  	const {
	   languages = [],
	   locations = [],
	   serviceCategory = [],
	   mode = ""
  	} =  userDetails?.catalystDetails || {};
	
	return (
		<div className="container-fluid my-3">
			
			<div className="row">
				<div className="flex_container_60 bg-white border_50">
					
					<div className="">
						<div className="flex_container_20 w-100 justify-content-center">
							<img src={profilePic || avatar} className="catalyst_overview_profile_img py-3" />
							
							<h5 className="heading-5 color-primary-50">{name}</h5>
							<div className="body-R color-primary-50">{email}</div>
							<div className="body-R color-primary-50">{phone}</div>
						</div>

						<hr />

						<div className="flex_container_20">
							<h5 className="heading-5 color-primary-50">Address</h5>
							<div className="body-R color-primary-50">{apartment} {state} {city} {state}, {country}</div>
						</div>

						<hr />

						<div className="flex_container_20">
							<h5 className="heading-5 color-primary-50">About Me</h5>
							
							<div className="body-R color-primary-50">{publicName || name} (My public name)</div>
							<div className="body-R color-primary-50">
								{aboutMe || "-"}
							</div>
						</div>

						<hr />
					</div>
				</div>

				<div className="flex_container_60 bg-white border_50">
					<h4 className="heading-4 color-primary-50 pb-3">Service Details</h4>
					<div className="flex_container_20">
						<h5 className="heading-5 color-primary-50">Primary Expertise</h5>
						<div className="d-flex py-3">
							{serviceCategory?.length > 0 && serviceCategory.map((item, index) => {
								return (
									<div className="category_container me-3">{item?.name || "-"}</div>
								)
							})}

							{serviceCategory.length === 0 && <div className="me-3">-</div>}
						</div>
					</div>

					<hr />

					<div className="flex_container_20">
						<h5 className="heading-5 color-primary-50">Languages of Service</h5>
						
						<div className="d-flex py-3">
							<div className="d-flex py-3">
								{languages?.length > 0 && languages.map((item, index) => {
									return (
										<div className="category_container me-3">{item?.value || "-"}</div>
									)
								})}
							</div>

							{languages.length === 0 && <div className="me-3">-</div>}
						</div>
						
					</div>

					<hr />

					<div className="flex_container_20">
						<h5 className="heading-5 color-primary-50">Modes of Services</h5>
						
						<div className="flex_container_20">
							<div className="color-primary-50 body-R">{mode}</div>
						</div>
					</div>

					<hr />

					<div className="flex_container_20">
						<h5 className="heading-5 color-primary-50">Tax number</h5>
						
						<div className="flex_container_20">
							<div className="color-primary-50 body-R">-</div>
						</div>
					</div>
					
					<hr />

					<div className="flex_container_20">
						<h5 className="heading-5 color-primary-50">Company name</h5>
						
						<div className="flex_container_20">
							<div className="color-primary-50 body-R">{companyDetails?.companyName || "-"}</div>
						</div>
					</div>

					<hr />

					<div className="flex_container_20">
						<h5 className="heading-5 color-primary-50">Instragram</h5>
						
						<div className="flex_container_20">
							<div className="color-primary-50 body-R">{socialMediaHandles && socialMediaHandles[1] && socialMediaHandles[1].link || "-"}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default OverView