import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SubscriptionView from '../../../../components/subscriptionView/SubscriptionView';

import partner_icon from '../../../../assets/Partners.png';
import '../../../../components/subscriptionView/subscriptionView.css';

const SubscriptionDetails = (props) => {

	const {CTALabel = "", pageName = "", isDisabled = "false", onHandlePlanDetailsChange, planDetails = {}} = props;

	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));
    
    useEffect(() => {
    	if(loggedUser && loggedUser?.role) {
    		setPlanDetails(loggedUser?.catalystDetails?.planDetails);
    	}
    }, [loggedUser]);

	const [subscriptionDetails, setSubscriptionDetails] = useState(null);
	const [subscriptionList, setSubscriptionList] = useState([0,1,2]); 

	const [subPlanDetails, setPlanDetails] = useState({});

	useEffect(() => {
		if(planDetails && planDetails?.subscriptionId)
		setPlanDetails(planDetails);
	}, [planDetails])

	const onUpdateSuccess = () => {
		//fetchSubscriptions();
	}
	
	return (
		<>
			<SubscriptionView 
				pageName={pageName} 
				isDisabled={isDisabled}
				CTALabel = {CTALabel}
				onHandlePlanDetailsChange = {onHandlePlanDetailsChange}
				planDetails = {subPlanDetails}

			/>
		</>
	)
}

export default SubscriptionDetails;
