import * as React from 'react';
import TextField from '@mui/material/TextField';
import './input.css';

const Input = (props) => {
  const { 
    classVal = "",
    isError = false, 
    label = "", 
    type="text", 
    name="", 
    value="", 
    isDisabled=false, 
    isShowLabel, 
    isRequired=false,
    placeHolder="",
    onInputChange
  } = props;

  const onHandleChange = (e) => {
    onInputChange(e);
  }

  return (
    <>
      <TextField 
        className={`w-100 ${classVal}`}
        disabled={isDisabled}
        value={value}
        error={isError}
        aria-invalid={isError}
        label={label}
        id={name}
        name={name}
        type={type}
        required={isRequired}
        placeholder={placeHolder}
        
        variant="standard"
        onChange={(e) => onHandleChange(e)} 
      />
    </>
  );
}

export default Input;
