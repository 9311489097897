import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './selectView.css';

const FloatSelectView = (props) => {
  const {
    name = "", 
    value = "", 
    label = "", 
    list = [], 
    onInputChange, 
    extraText = "", 
    valFieldName = "value", 
    labelFieldName = "label",
    isDisabled = false
  } = props;

  const handleChange = (e) => {
    onInputChange(e);
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, width: '100%' }} className="select">
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      
      <Select
        name={name}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={handleChange}
        label="Age"
        disabled = {isDisabled}
      >
        {/*<MenuItem value = "">
          <em>None</em>
        </MenuItem>*/}

        {list.map((item,index) => {
          return <MenuItem label={item[labelFieldName]} value={item[valFieldName]}>{item[labelFieldName]} {extraText}</MenuItem>
        })
        }
       
      </Select>
    </FormControl>
  )
}

export default FloatSelectView;



