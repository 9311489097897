import { useState, useEffect } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";

import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";

import {GOOGLE_API} from '../../actions/Constants';

import './autocomplete.css';

export default function MapLocationAutoComplete(props) {
  const {name = "", selectedAddress = null, onSelectLocation} = props;
  const [address, setAddress] = useState(selectedAddress);
  const [addressObj, setAddressObj] = useState();

  setDefaults({
    key: GOOGLE_API, // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
  });

  useEffect(() => {
    if(!selectedAddress) {
      setAddress(selectedAddress);
    }
  }, [selectedAddress]);

  const getLocationDetails = async (address = {}, addressObj = {}) => {
    geocode(RequestType.ADDRESS, address?.label)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        //console.log(lat, lng);

        const obj = {};
        obj.address = address;
        obj.city = addressObj?.city;
        obj.country = addressObj?.country;
        obj.coordinate =  { lat: lat, lng: lng }

        onSelectLocation(obj);
      })
      .catch(console.error);
  }

  const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5"
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4"
      ],
      country: ["country"]
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: ""
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "IN") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  useEffect(() => {
    
    const func = async () => {
      const geocodeObj =
        address && address.value &&
        (await geocodeByPlaceId(address.value.place_id));
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components);
        
        setAddressObj(addressObject);
        onSelectLocation(address);

        if(address) {
          getLocationDetails(address, addressObject);
        }
    };
    func();
  }, [address]);

  return (
    <div >
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_API}
        name={name}
        selectProps={{
          placeholder: 'Address',
          isClearable: true,
          value: address,
          onChange: (val) => {
            setAddress(val);
          }
        }}
      />
      {/*<pre style={{ textAlign: "left", background: "#f0f0f0", padding: 20 }}>
        {JSON.stringify(addressObj, 0, 2)}
      </pre>*/}
    </div>
  );
}


