import {useState, useEffect, useRef} from 'react';
import MultiSelectDropdown from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import {Category_List} from '../../../utils/DefaultInitialValues';

const AddObjective = (props) => {
  const dialogRef = useRef();
	const {
    	userDetails = {},  
    	userErrors = {}, 
    	onDropdownChange
  	} = props;

  	const {
  		interestCategories = [],
  		stuggleCategories = []
		} = userDetails?.profileDescription || {}; 

    useEffect(() => {
      try {
        dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } finally {
      }
    }, []);

	return (
		<div className="container dialog_padding" ref={dialogRef}>
  		<div className="row">
    		<div className="col-lg-6 m-auto">
			    <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
      			<h4 className="heading-5 color-primary-50">2.1 Additional Information</h4>

      			<label for="interestCategories" className="heading-6 color-primary-50">What interests you the most...</label>
      			<div className="my-1 w-100 select">
              <MultiSelectDropdown name="interestCategories" id="interestCategories" selectedValue = {interestCategories} onDropdownChange = {(name, list) => onDropdownChange(name, list)} options = {Category_List} />
            </div>

            <div className="mb-4 LINK color-secondary-100 capitalize fs-6">Not able to find your interest? 
          			<a className="ax-button fw-bold LINK color-secondary-100 fs-6" href="mailto:hello@theminded.club"> Request for new</a>
        		</div>

            <label for="stuggleCategories" className="heading-6 color-primary-50">I’m here to explore...</label>
      			<div className="my-1 w-100 select">
              <MultiSelectDropdown name="stuggleCategories" id="stuggleCategories" selectedValue = {stuggleCategories} onDropdownChange = {(name, list) => onDropdownChange(name, list)} options = {Category_List} />
            </div>

            <div className="mb-4 LINK color-secondary-100 capitalize fs-6">Not able to find your interest? 
          			<a className="ax-button fw-bold LINK color-secondary-100 fs-6" href="mailto:hello@theminded.club"> Request for new</a>
        		</div>
	        </div>
	      </div>
	    </div>
    </div>
	)
}

export default AddObjective;