import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import CustomerListView from '../../settings/CustomerListView';
import Loading from '../../../../../components/loading/Loading';
import Noresult from '../../settings/Noresult';
import {baseURL} from '../../../../../actions/Constants';
import {getCustomers} from '../../../../../actions/customers.actions';
import {headerOption} from '../../../../../utils/DefaultInitialValues';

const CustomerView = (props) => {
    const navigate = useNavigate();
	const dispatch = useDispatch();
	const getCustomersAction = getCustomers;

	const [allCustomers, setAllCustomers] = useState([]);
	const [filterCustomers, setFilterCustomers] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const getAllCustomersWithBookings = async () => {
        setLoading(true);

        const URL = `${baseURL}/minded-services/admin/user-bookings/`;

        return await fetch(URL, headerOption)
        .then(response => {
          const resp = response;

          const {status = ""} = resp;
          setLoading(false);
          if(status === 200 || status === 201) {
            return response.json().then(res => {
             // setError(false);
                setAllCustomers(res);
                setFilterCustomers(res);
            });
            
          } else {
            //Error
           setError(false);
          }
        })
        .catch((error) => {
          setLoading(true);
          console.log('error getAllCustomersWithBookings', error);
          setError(true);
           
        });
    }

    const onViewAllCustomers = () => {
        navigate("/dashboard/customers");
    }

    useEffect(() => {
        getAllCustomersWithBookings();
    }, []);

	return (
		<>
            <div className="list_view w-100">
                {filterCustomers.length === 0 && <div> <Noresult serviceName = "Classes" onCTAClick = {() => console.log("Classes")} /> </div>}
                {filterCustomers.length > 0 && <CustomerListView data = {filterCustomers} />}
            </div>
            {filterCustomers.length > 0 && <div className="w-100 d-flex justify-content-center">
                <button className="width-50 tertiary_btn color-primary-50" onClick = {onViewAllCustomers}>View All Customers</button>
            </div>}

            {isLoading && <Loading />}
            {(isError) && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." />}
        
        </>
	)
}

export default CustomerView;