import {
  GET_BOOKINGS_START,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
  baseURL
} from './Constants';
import {headerOption} from '../utils/DefaultInitialValues';

export const getBookings = (bookingId = "", scheduleId = "", serviceId = "", userId = "", purchaseId = "", bookingStatus = "",  bookingStartDate = "", bookingEndDate = "", purchaseType = "") => async (dispatch) => { 

  dispatch({
    type: GET_BOOKINGS_START,
    error: null
  });

  let param = "";
  let isExist = false;

  if(bookingId) {
    isExist = true;
    param +=  `bookingId=${bookingId}`;
  }
  
  if(scheduleId) {
    if(isExist) param += "&";
    param +=  `scheduleId=${scheduleId}`;
    isExist = true;
  }

  if(serviceId) {
    if(isExist) param += "&";
    param +=  `serviceId=${serviceId}`;
    isExist = true;
  }

  if(userId) {
    isExist = true;
    param +=  `userId=${userId}`;
  }

  if(purchaseId) {
    isExist = true;
    param +=  `purchaseId=${purchaseId}`;
  }

  if(bookingStatus) {
    if(isExist) param += "&";
    param +=  `&bookingStatus=${bookingStatus}`;
    isExist = true;
  }

  if(bookingStartDate) {
    if(isExist) param += "&";
    param +=  `bookingStartDate=${bookingStartDate}`;
    isExist = true;
  }

  if(bookingEndDate) {
    if(isExist) param += "&";
    param +=  `&bookingEndDate=${bookingEndDate}`;
    isExist = true;
  }

  if(purchaseType) {
    if(isExist) param += "&";
    param +=  `&purchaseType=${purchaseType.toUpperCase()}`;
    isExist = true;
  }

  /*let myList = [];
  if(serviceType === "ticket") {
    myList = myTicketsBooking;
  } else if(serviceType === "package") {
    myList = myPackagesBooking;
  } else {
    myList = myPlansBooking;
  }
  */

  const obj = {};
  const URL = `${baseURL}/minded-services/getBookingDetails?${param}`;

  return await fetch(URL, headerOption)
  .then(response => {
    const resp = response;

    const {status = ""} = resp;

    if(status === 200 || status === 201) {
      return response.json().then(res => {
        let list = [];
        
        list = res.filter(item => item.purchaseType === purchaseType.toUpperCase());

        dispatch({
          type: GET_BOOKINGS_SUCCESS,
          payload: list,
          error: null
        });
      });
      
      
    } else {
      //Error
      dispatch({
        type: GET_BOOKINGS_FAILURE,
        error: true,
      });
    }
  })
  .catch((error) => {
    console.log('error getBookings', error);
    //console.log('error getSchedules', error);
    dispatch({
      type: GET_BOOKINGS_FAILURE,
      error: true,
    });
  });
}



