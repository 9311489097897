import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import RadioCard from '../radio-card/RadioCard';
import WeekViewLayout from './WeekViewLayout';
import DayViewLayout from './DayViewLayout';
import {getWeekDays, getTypeFromActiveTab, isEmptyJson} from '../../utils/util';
import {saveFilters} from '../../actions/filters.actions';

import arrow_forward from '../../assets/Arrow forward.png';
import arrow_backward from '../../assets/Arrow backward.png';
import './calendarView.css';

const ClassListView = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const saveFiltersAction = saveFilters;
	
	const {
		onHandleCTAClick,
		serviceList = [], 
		CTALabel = "Book Now",
		isCTAShow = true,
		activeTab = -1,
		service_Type = "CLASS",
		isCTADisabled = false
	} = props;

	const [filters, setFilters] = useState({});

	const {
       details: filterDetails
    } = useSelector(({ filter: { details} }) => ({ details }));

    const {
    	details: loggedUser
  	} = useSelector(({ loggedAdmin: { details} }) => ({ details }));

	useEffect(() => {
		if(!isEmptyJson(filterDetails) && filterDetails?.days?.length > 0) {
			setFilters(filterDetails);
		}
	}, [filterDetails]);

    const {
    	isDayView = "false",
		days = [], 
		isMobileView = false,
		startDate = new Date()
    } = filters || {};

    const onHandleItemClick = (item = {}) => {
    	navigate(`/events-classes/schedule-details/${item.scheduleId}`)
    }

    const dayDisplayCount = (isDayView === "true" && isMobileView) ? 1 : 7;

    //console.log(isCTADisabled);
    //console.log((loggedUser && loggedUser?.role && (loggedUser?.role === "admin" || loggedUser?.role === "catalyst")))

	return (
		<>
          
			<div className="catalyst-details-calendar-container">
				
				{[...Array(dayDisplayCount).keys()].map((item, index) => {
					let indexval = (isDayView === "true" && isMobileView) ? startDate.getDay() : index;
                    const currentDate = days[item];

					return (
						<>
							<div key={indexval} className="d-flex flex-column align-items-center py-lg-5">
								<RadioCard 
									startDate = {startDate} 
									data = {item} 
									name="days" 
									index = {indexval} 
									currentDay = {currentDate} 
									isDayView = {isDayView}  
								/>
								
								{isDayView === "false" && 
									<WeekViewLayout 
										indexval = {indexval} 
										onHandleItemClick = {onHandleItemClick}
										onHandleCTAClick = {onHandleCTAClick}
										serviceList = {serviceList} 
										currentDate = {currentDate}
										activeTab = {activeTab}
										CTALabel = {CTALabel}
										isCTAShow = {isCTAShow}
										service_Type = {service_Type}
										isCTADisabled = {isCTADisabled || (loggedUser && loggedUser?.role && (loggedUser?.role === "admin" || loggedUser?.role === "catalyst"))}

										
									/>
								}
							</div>
						</>
					)
				}
				)}
            </div>
			
			{isDayView === "true" && 
				<DayViewLayout 
					indexval = {0} 
					onHandleItemClick = {onHandleItemClick}
					onHandleCTAClick = {onHandleCTAClick}
					serviceList = {serviceList}
					currentDate = {startDate}
					startDate = {startDate}
					activeTab = {activeTab}
					CTALabel = {CTALabel}
					isCTADisabled = {isCTADisabled || (loggedUser && loggedUser?.role && (loggedUser?.role === "admin" || loggedUser?.role === "catalyst"))}

				/>
			}
		</>
	)
}

export default ClassListView