import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loading from  '../../components/loading/Loading';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import {headerOption} from '../../utils/DefaultInitialValues';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {baseURL} from '../../actions/Constants';

import Slide from '@mui/material/Slide';

import PurchaseView from './PurchaseView';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialogView = () => {
      const [open, setOpen] = useState(true);
      const [isLoading, setLoading] = useState(false);
      const [isError, setError] = useState(false);
      const [transactionId, setTransactionId] = useState("");

      const {id} = useParams();

      const [msg, setMsg] = useState("");

      const navigate = useNavigate();

      const [purchaseDetails, setPurchaseDetails] = useState({});
     
      const getTransactionStatus = async (transactionId) => {
        setLoading(true);

        const URL = `${baseURL}/minded-services/check_transaction_status/${transactionId}`;

        return await fetch(URL, headerOption)
        .then(response => {
          const resp = response;

          const {status = ""} = resp;
          setLoading(false);
          console.log("transaction status --------------->", status);
          
          if(status === 200 || status === 201) {
            return response.json().then(res => {
              // setError(false);
              console.log(res);
              setMsg(res?.paymentStatus);
            });
            
          } else {
            //Error
            setError(true);
          }
        })
        .catch((error) => {
          console.log('error bookSchedule', error);
          setError(true);
          setMsg(error?.returnStatus?.statusMsg);
        });
      }

      const retry = () => {
        getTransactionStatus(transactionId);
      }

      const getPurchaseDetails = async (purchaseId) => {
        setLoading(true);

        const URL = `${baseURL}/minded-services/getPurchaseDetails?purchaseId=${purchaseId}`;

        return await fetch(URL, headerOption)
        .then(response => {
          const resp = response;

          const {status = ""} = resp;
          setLoading(false);
          if(status === 200 || status === 201) {
            return response.json().then(res => {
              console.log(res)
              // setError(false);
              setPurchaseDetails(res[0]);
              const tID = res[0]?.paymentDetails?.transactionID;
              setTransactionId(tID);
              getTransactionStatus(tID);
            });
          } else {
            //Error
            setError(true);
          }
        })
        .catch((error) => {
          console.log('error bookSchedule', error);
          setError(true);
        });
      }

      useEffect(() => {
        if(id) {
          console.log(id)
          getPurchaseDetails(id);
        }
      }, []);

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        //setOpen(false);
       // onHandleClose();
      };

      const handleDone = () => {
        navigate("/dashboard")
      }

      return (
      <div className="full-dialog">
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >

        <DialogActions className="d-flex justify-content-between align-items-center p-4">
            <div>
            </div>

            <h4 className="heading-4 color-primary-50">
              Confirmation
            </h4>

            <div>
              {/*
              <button     
                onClick={handleClose}
                aria-label="close"
                className="close_icon ax-button"
              >
                <img src={brown_cancel} className="close_icon" />
              </button>*/}
            </div>
        </DialogActions>

        <DialogContent>
          <PurchaseView purchaseDetails = {purchaseDetails} statusMsg = {msg} retry = {retry} />
        </DialogContent>

        <DialogActions className="d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className="card-container d-flex justify-content-between align-items-center dialog_padding">
              <div className="d-flex justify-content-between align-items-center">
                <div className="px-3 subtitle-R color-primary-50"></div> 
              </div>

              <div className="d-flex">
                <button className="mx-3 secondary_btn capitalize" onClick={handleDone}>Done</button>
              </div>
            </div>
          </div>
        </DialogActions>

        {isLoading && <Loading />}
        {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />} 
      </Dialog>
    </div>
  );
}

export default ConfirmDialogView;