import {useState, useEffect, useRef} from 'react';
import AddLocationView from '../../../components/addLocationView/AddLocationView';
import Input from '../../../components/input/Input';
import Textarea from '../../../components/textarea/Textarea';
import MultiSelectDropdown from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import MapLocationAutoComplete from '../../../components/location/LocationAutocomplete';
import {Category_List, InitialProfileDetails, Mode_Array} from '../../../utils/DefaultInitialValues';
import {LangList} from '../../../utils/DefaultStates';

const CatalystProfileDetails = (props) => {
  const dialogRef = useRef();

	const {
    userErrors = {},
    userDetails = {}, 
    addLocation, 
    onHandleChange, 
    boardingErrors = {}, 
    onDropdownChange, 
    onLocationChange,
    onHandleCatalystDetailsChange, 
    handleLocationChange,
    onHandleProfileChange,
    onHandleProfileCompanyChange,
    onHandleProfileSocialChange
  } = props;

  const {
    languages = [],
    mode = "",
    locations = [],
    serviceCategory = []
  } = userDetails?.catalystDetails || {};

  const {
    aboutMe = "",
    publicName = "",
    companyDetails = {
      companyName: "",
      url: ""
    },
    socialMediaHandles = [
      {
        name:"facebook",
        link: ""
      },
      {
        name :"instagram",
        link:""
      }
    ]
  } = userDetails?.profileDescription || {};

  const {
    expertiseError = false,
    languageError = false
  } = userErrors || {};

  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {

    }
  }, []);

	return (
		<>
      <div className="container dialog_padding" ref={dialogRef}>
      	<div className="row">
      		<div className="col-lg-6 m-auto">
            
            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50 color-primary-50">
              <h5 className="heading-5 color-primary-50">2.1 Service/Expertise Info</h5>
            
              <div className="mb-2 w-100">
                <label for="serviceCategory" className="heading-6 color-primary-50 py-2">Expertise</label>
                
                <MultiSelectDropdown 
                  name="serviceCategory" 
                  id="serviceCategory" 
                  selectedValue = {serviceCategory} 
                  onDropdownChange = {onDropdownChange} 
                  options = {Category_List} 
                />

                {expertiseError && <div className="text-danger pt-1">! Expertise category is a required field</div>}
              </div>

              <div className="mb-4 LINK color-secondary-100">Not able to find your service? 
                <a className="ax-button fw-bold LINK color-secondary-100" href="mailto:hello@theminded.club"> Request for new</a>
              </div>

              <div className="mb-2 w-100">
                <label for="services" className="heading-6 color-primary-50 py-2">Language of service</label>
                <MultiSelectDropdown 
                  name="languages" 
                  id="languages" 
                  selectedValue = {languages} 
                  onDropdownChange = {onDropdownChange} 
                  options = {LangList}
                  viewLabel = "value"
                />
                {languageError && <div className="text-danger">! Language is a required field</div>}
              </div>

              <div className="mb-2 w-100">
                <label for="services" className="heading-6 color-primary-50">Mode of service</label>
                <div className="my-2">
                  {Mode_Array.map((item, index) => 
                    <>
                      <input 
                        type="radio"
                        name="mode" 
                        id={item.value} 
                        className="mode px-2" 
                        value = {item.value}
                        checked= {item.value === mode}
                        onChange={(e) => onHandleCatalystDetailsChange(e)} 
                      />
                      <label for={item.value} className="pe-3">
                        <div className="">
                          <div className="d-flex align-items-center">
                            <span className="body-R px-2">{item.label}</span>
                          </div>
                        </div>
                      </label>
                    </>
                  )}
                </div>
              </div>

              <div className="mb-2 w-100">
                <label for="location" className="heading-6 color-primary-50">Location</label>
                <AddLocationView list = {locations} handleLocationChange = {handleLocationChange} />  
              </div>

              <div className="w-100">
                <button type="button" className="capitalize tertiary_btn w-100" onClick={addLocation}>Add More Locations</button>
              </div>       
            </div>
             
            <div className = "w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50 color-primary-50">
              <h5 className="heading-5 color-primary-50">1.3 Others</h5>
              <div className="mb-2 w-100">
                <Input
                  name = "companyName"
                  label = "Company name (if any)"
                  value = {companyDetails?.companyName}
                  onInputChange = {(e) => onHandleProfileCompanyChange(e)} 
                />
              </div>

              <div className="mb-2 w-100">
                <Input
                  name = "instralink"
                  label = "Instagram  link (if any)"
                  value = {socialMediaHandles[1].link}
                  onInputChange ={(e) => onHandleProfileSocialChange(e, 1)} 
                />
              </div>
            </div>
      		</div>
      	</div>
      </div>
    </>
	)
}

export default CatalystProfileDetails;