import {
  GET_CATALYSTS_START,
  GET_CATALYSTS_SUCCESS,
  GET_CATALYSTS_FAILURE,
  baseURL
} from './Constants';

import {
  getAuthenticateHeader,
  getCookie
} from '../utils/util';

import {headerOption} from '../utils/DefaultInitialValues';

export const getPopularCatalystAll = () => async (dispatch) => { //Payroll
  dispatch({
    type: GET_CATALYSTS_START,
    error: null
  });
  
  const URL = `${baseURL}/minded-user/getUsersByRole/catalyst`;

  return await fetch(URL, headerOption)
  .then(response => {
    const resp = response;

    const {status = ""} = resp;

    if(status === 200 || status === 201) {
      return response.json().then(res => {
        dispatch({
          type: GET_CATALYSTS_SUCCESS,
          payload: res,
          error: null
        });
      });
    } else {
      //Error
      dispatch({
        type: GET_CATALYSTS_FAILURE,
        error: true,
      });
    }
  })
  .catch((error) => {
    console.log('error getCatalystAll', error);
    //console.log('error getSchedules', error);
    dispatch({
      type: GET_CATALYSTS_FAILURE,
      error: true,
    });
  });
}

export const getCatalystAll = () => async (dispatch) => { //Payroll
  dispatch({
    type: GET_CATALYSTS_START,
    error: null
  });
  
  const URL=`${baseURL}/minded-user/getUsersByRole/catalyst`;

  return await fetch(URL, headerOption)
  .then(response => {
    const resp = response;

    const {status = ""} = resp;

    if(status === 200) {
      return response.json().then(res => {
        dispatch({
          type: GET_CATALYSTS_SUCCESS,
          payload: res,
          error: null
        });
      });
    } else {
      //Error
      dispatch({
        type: GET_CATALYSTS_FAILURE,
        error: true,
      });
    }
  })
  .catch((error) => {
    console.log('error getCatalystAll', error);
    //console.log('error getCatalystAll', error);
    dispatch({
      type: GET_CATALYSTS_FAILURE,
      error: error,
    });
  });
}





