import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from "rsuite";

import OnBoardingDialog from '../../../pages/admin/onBoardingAdmin/OnBoardingDialog';
import avatar from '../../../assets/avatar.png';
import right_arrow from '../../../assets/thin arrow - right.png';

const ProfileDetails = () => {
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);

	const [isShowDetails, setShowDetails] =  useState(false);
	const [user, setUser] = useState({});
	const [navigateStepper, setNavigateStepper] = useState(0);

	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));
	
	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "profile":
					obj.path = "/dashboard/profile";
					obj.label = "Profile";
					crumbArray.push(obj);
					break;
				default: break;
				
			}

			setCrumb(crumbArray);
		});
	}

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    useEffect(() => {
    	if(loggedUser && loggedUser.role) {
    		setUser(loggedUser);
    	}
    }, [loggedUser]);

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

    const onEditTrigger = (val) => {
    	let indexVal = val;
  
    	if(role === "customer") {
    		indexVal = val - 2;
    	} else if(role === "admin") {
    		indexVal = val - 2;
    	} else {
    		indexVal = val - 1;
    	}

    	setNavigateStepper(indexVal);
    	setShowDetails(true);
    }

    const {
	    profilePic = "",
	    publicName = "",
	    aboutMe = "",
	    companyDetails = {},
	    socialMediaHandles = []
  	} =  user?.profileDescription || {};

  	const {
  		companyName = ""
  	} = companyDetails || {};

	const {
		city = "",
		pincode = "",
		country = ""
	} = user?.address || {};

	const {
		role = "customer",
	  	email = "",
	  	name = "",
	  	phone = "",
	  	catalystDetails = {}
	} = user || {};

	const {
		mode = ""
	} = catalystDetails || {};

	return (
		<div className="main-no-content-padding">
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt = "" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">Profile Details</h1>
			
			<div className="container-fluid mt-5">
				<div className="row">
 	 				<div className="col-lg-6 m-auto">
 	 					
 	 					<div className="flex_container_70 bg-white border_50 padding_70 w-100">
	 	 					
	 	 					<div className="w-100 d-flex justify-content-between">
	 	 						<h4 className="heading-4 color-primary-50">Personal Details</h4>
	 	 						<button className="ax-button capitalize color-secondary-100 ax-button fs-6" onClick = {() => onEditTrigger(4)}>Edit</button>
	 	 					</div>
	 	 					
	 	 					<div className="w-100">
								<div className="">
									<img src={profilePic || avatar } className="catalyst_overview_profile_img py-3" alt = "" />
									
									<div className="flex_container_30 flex-column py-2">
										<h5 className="heading-5 color-primary-50">{name || "-"}</h5>
										<div className="body-R color-primary-50">{email || "-"}</div>
										<div className="body-R color-primary-50">{phone|| "-"}</div>
									</div>
								</div>

								<hr />

								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">Address</h5>
									<div className="body-R color-primary-50">{city}, {country}, {pincode}</div>
								</div>

								<hr />

								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">About Me</h5>
									
									{publicName !== "" && <div className="body-R color-primary-50">{publicName} (My public name)</div>}
									<div className="body-R color-primary-50">
										{aboutMe || "-"}
									</div>
								</div>

								<hr />
								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">Language Speaks</h5>
									<div className="body-R color-primary-50">-</div>
								</div>

							</div>
						</div>

						{role === "catalyst" && <div className="flex_container_70 bg-white border_50 padding_70 w-100 my-5">
	 	 					<div className="w-100 d-flex justify-content-between align-items-center">
	 	 						<h4 className="heading-4 color-primary-50">Service Details</h4>
	 	 						<button className="ax-button capitalize color-secondary-100 ax-button" onClick = {() => onEditTrigger(5)}>Edit</button>
	 	 					</div>
	 	 					
	 	 					<div className="w-100">
								
								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">Modes of Services</h5>
									<div className="body-R color-primary-50">{mode}</div>
								</div>

								<hr />

								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">Tax Number</h5>
									<div className="body-R color-primary-50">-</div>
								</div>

								<hr />

								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">Company Name</h5>
									<div className="body-R color-primary-50">{companyName || "-"}</div>
								</div>

								<hr />

								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">Instragram</h5>
									<div className="body-R color-primary-50">{socialMediaHandles && socialMediaHandles[1] && socialMediaHandles[1].link || "-"}</div>
								</div>
							</div>
						</div>}

						{(role === "customer" || role === "admin") && <div className="flex_container_70 bg-white border_50 padding_70 w-100 my-5">
	 	 					<div className="w-100 d-flex justify-content-between align-items-center">
	 	 						<h4 className="heading-4 color-primary-50">My Objectives Details</h4>
	 	 						<button className="ax-button capitalize color-secondary-100 ax-button" onClick = {() => onEditTrigger(5)}>Edit</button>
	 	 					</div>
	 	 					
	 	 					<div className="w-100">
								
								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50">What interests you the most...</h5>
									<div className="body-R color-primary-50">-</div>
								</div>

								<hr />

								<div className="flex_container_30 flex-column">
									<h5 className="heading-5 color-primary-50"> I’m here to explore...</h5>
									<div className="body-R color-primary-50">-</div>
								</div>

								

								
							</div>
						</div>}
					</div>
				</div>
			</div>
			{isShowDetails && <OnBoardingDialog 
				isOpen = {isShowDetails}
				userinfo = {user}
				onHandleClose={() => setShowDetails(false)} 
				navigateStepper = {navigateStepper} />
			}
				
		</div>
	)
}

export default ProfileDetails;