import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';

import AddServiceDialog from '../../../admin/createService/serviceDialog';
import ActionMenu from '../../../../components/actionMenu/ActionMenu';
import SchedulePublishDialog from '../../../admin/schedule/SchedulePublishDialog';
import Noresult from '../../../../components/no-result/Noresult';

import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import ClassListView from '../../../../components/classListView/ClassListView';
import FilterView from '../../../../components/filters/FilterView';
import HelmetDetails from "../../../../components/HelmetDetails";
import HelmetText from '../../../../components/helmet-text/HelmetText';

import CatalystView from '../detailsView/sub-view/CatalystView';
import ServiceView from '../detailsView/sub-view/ServiceView';
import SelectwithoutLabel from '../../../../components/selectwithoutLabel/SelectWithoutLabel';
import CustomerView from '../detailsView/sub-view/CustomerView';

import PaymentView from '../detailsView/sub-view/PaymentView';

import Loading from '../../../../components/loading/Loading';
import Table from '../../../../components/table/Table';
import TabView from '../../../../components/tabView/TabView';
import {tabList, Service_Tab_List, Service_Status_Array, headerOption} from '../../../../utils/DefaultInitialValues';

import {Admin_Dashboard_Data} from '../../../../assets/data';
import ListView from '../ListView';

import {getCatalystAll} from '../../../../actions/catalysts.actions';
import {getSchedules} from '../../../../actions/schedules.actions';
import {baseURL} from '../../../../actions/Constants';
import {
	getAuthenticateHeader, 
	getCurrentMonthFirstDayEpoc, 
	getCurrentMonthText, 
	generateEventsDropdownList, 
	getTypeFromActiveTab, 
	getFormattedCurrentMonthFirstDate, 
	getFormattedCurrentMonthTodayDate
} from '../../../../utils/util';

import Catalyst_bg from '../../../../assets/catalyst_3.png';
import Refresh from '../../../../assets/Refresh.png';
import location from '../../../../assets/Location pin.png';
import avatar from '../../../../assets/ic_avatar.png';
import '../dashboard.css';

const AdminDashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [user, setUser] = useState({});

	const getSchedulesAction = getSchedules;

	const currentMonth = getCurrentMonthText();
    
	const [isShowDialog, setShowDialog] = useState(false);
	const [isShowServiceDialog, setShowServiceDialog] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [serviceStatus, setServiceStatus] = useState("active");

	const [allSchedules, setAllSchedules] = useState([]);
	const [filterSchedules, setFilterSchedules] = useState([]);

	const [startEpoc, setStartEpoc] = useState("");
	const [endEpoc, setEndEpoc] = useState("");

	const [allDropdownList, setAllDropdownList] = useState({});
	const [service_Type, setServiceType] = useState("CLASS");

	const [scheduleDetails, setScheduledetails] = useState({});

	const [activeCatalystCount, setActiveCatalystCount] = useState("--");
	const [activeMinderCount, setActiveMinderCount] = useState("--");
	const [adminRevenuePrice, setAdminRevenuePrice] = useState("--");
	const [totalSoldTicketCount, setTotalTicketSoldCount] = useState("--");

	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

	const {
    	details: loggedUser
  	} = useSelector(({ loggedAdmin: { details} }) => ({ details }));

    const {
       details,
       loading,
       error
    } = useSelector(({ schedules: { details, loading, error} }) => ({ details, loading, error }));

    const {
       details: catalystDetails,
       loading: catalystLoading,
       error: catalystError
    } = useSelector(({ catalysts : { details, loading, error} }) => ({ details, loading, error }));


    const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    useEffect(() => {
    	if(loggedUser && loggedUser?.role) {
    		setUser(loggedUser);
    	}
    }, [loggedUser]);

    useEffect(() => {
    	if(Array.isArray(allSchedules)) {	
    		const obj = generateEventsDropdownList(allSchedules);
    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [allSchedules]);

    useEffect(() => {
		const type = getTypeFromActiveTab(activeTab);
		setServiceType(type);

		dispatch(getSchedulesAction("", "", type, "", startEpoc, endEpoc, "", ""));
	}, [activeTab]);
	
    useEffect(() => {
    	if(Array.isArray(details)) {
    		setAllSchedules(details);
    	}
    }, [details]);

    useEffect(() => {
    	applyFilter(allSchedules);
    }, [selectedFilters]);

     useEffect(() => {
    	if(Array.isArray(allSchedules)) {	
    		const obj = generateEventsDropdownList(allSchedules);
    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [allSchedules]);

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	getCatalystById();
    	getActiveCatalystCount();
    	getActiveMinderCount();
    	getTotalTicketSoldDetails();
    	getAdminRevenueDetails();
    }, []);

    const applyFilter = () => {
  
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = allSchedules;

    	if(selectedCatalyst.length) {
    		filterArray = allSchedules.filter((item, index) => selectedCatalyst.includes(item?.serviceDetails?.userDetails?.userId));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => selectedCategory.includes(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item?.serviceDetails?.serviceLocation?.city));
    	}

    	setFilterSchedules(filterArray);
    }

    const getActiveCatalystCount = async () => {
		setLoading(false);
		//const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		//const currentEpoc = new Date().getTime();

		const currentMonthFirstDayEpoc = getFormattedCurrentMonthFirstDate();
		const currentEpoc = getFormattedCurrentMonthTodayDate();
		const URL = `${baseURL}/minded-user/getActiveUsersByRole/Catalyst?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setActiveCatalystCount(res?.count);
		      });
		    } else {
		      	//Error
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getActiveCatalystCount', error);
		});
	}

	const getActiveMinderCount = async () => {
		setLoading(false);

		//const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		//const currentEpoc = new Date().getTime();
		const currentMonthFirstDayEpoc = getFormattedCurrentMonthFirstDate();
		const currentEpoc = getFormattedCurrentMonthTodayDate();
		const URL = `${baseURL}/minded-user/getActiveUsersByRole/Customer?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setActiveMinderCount(res?.count);
		      });
		    } else {
		      	//Error
		      	
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getActiveCatalystCount', error);
		});
	}

	const getAdminRevenueDetails = async () => {
		setLoading(false);

		const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		const currentEpoc = new Date().getTime();
		//const URL = `${baseURL}/minded-user/getActiveUsersByRole/Customer?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;
		
		const URL = `${baseURL}/minded-services/admin/revenue?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		      	const totalRevenue = parseFloat(res?.totalRevenue).toFixed(2);
		      
		        setAdminRevenuePrice(totalRevenue);
		      });
		    } else {
		      	//Error
		      	
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getAdminRevenueDetails', error);
		});
	}

	const getTotalTicketSoldDetails = async () => {
		setLoading(false);

		const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		const currentEpoc = new Date().getTime();
		//const URL = `${baseURL}/minded-user/getActiveUsersByRole/Customer?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;
		
		const URL = `${baseURL}/minded-services/ticketsSoldByDate?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setTotalTicketSoldCount(res?.count);
		      });
		    } else {
		      	//Error
		      	
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getTotalTicketSoldDetails', error);
		});
	}

    const getCatalystById = async () => {
		setLoading(false);
		const URL = `${baseURL}/minded-user/getUserById/${loggedUser?.userId}`;

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setUser(res);
		      });
		    } else {
		      	//Error
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getCatalystById', error);
		});
	}

    const handleDateChange = (stDate, edDate) => {
    	const type = getTypeFromActiveTab(activeTab);

    	const startDateEpoc = new Date(stDate).getTime();
    	const endDateEpoc   = new Date(edDate).getTime();

    	setStartEpoc(startDateEpoc);
    	setEndEpoc(startDateEpoc);

    	dispatch(getSchedulesAction("", "", type, "", startDateEpoc, endDateEpoc, "", ""));
    }

    const onHandleServiceTitleClick = (eachservice) => {
    	const {service_id} = eachservice;
    	navigate(`/dashboard/event-details/${service_id}`);
    }

    const onHandleCTAClick = (schedule = {}) => {
		const newSchedule = schedule;
		setScheduledetails(newSchedule);
	}

    const TabPanel = (service_Type = "CLASS") => {
		return (
			<>
				<div className="container-fluid my-3">
					<FilterView 
						onHandleDateChange = {handleDateChange} 
						allDropdownList = {allDropdownList}
						onCheckboxSelect = {onCheckboxSelect} 
					/>
				</div>
			
				<ClassListView 
					onHandleCTAClick = {(schedule) => onHandleCTAClick(schedule)}
					serviceList = {filterSchedules}
					service_Type = {service_Type}
				/>
			</>
		);
	}

  	const {
		role = "",
		email = "",
		status = "pending",
		name = "",
		phone = "",
	} = user || {};

	const {
	    profilePic = "",
	    publicName = "",
	    aboutMe = ""
  	} = user?.profileDescription || {};

	return (
		<div className="main-no-content-padding">
			<HelmetDetails title="Admin Dashboard | Minded." />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex justify-content-between">
							<div className="">
								<h1 className="heading-1 color-primary-50">My Dashboard</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-lg-3 col-md-5">
						<div className="card-details rounded-4 pb-4 bg-white">
							<img src={profilePic || avatar} className="dashboard_profile_img d-flex justify-content-center" />
							<div className="p-4">
								<div className="">
									<h5 className="heading-5 color-primary-50">{name}</h5>
									<div>
										<img src={Refresh} />
										<span className="px-2 body-CAP color-primary-50">ACTIVE</span>
									</div>
								</div>
								
								<div className="body-R color-primary-50">{email}</div>
								<div className="body-R color-primary-50">{phone}</div>
							</div>
							<div className="px-4 d-flex justify-content-center w-100">
								<Link 
									to="/dashboard/profile" 
									className="text-center px-3 m-auto tertiary_btn color-primary-50 capitalize" 
								>
									VIEW PROFILE SETTINGS
								</Link>
							</div>
						</div>

						<div className="card-details rounded-4 pb-4 secondary_80_bg p-3 my-3">
							<h5 className="color-primary-50 heading-5">Quick Links</h5>
							<div className="d-flex flex-column align-items-start">
								<Link className="LINK color-secondary-100 ax-button capitalize my-1" to="/dashboard/settings">View Settings</Link>
							</div>
						</div>
					</div>

					<div className="col-lg-9 col-md-7">
						<div className="w-100 report_view px-1">
							<div className="w-100 d-flex justify-content-around align-items-center h-100">
								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{activeCatalystCount}</h2>
									<div className="caption-R color-primary-50">Active Catalysts</div>
								</div>

								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{activeMinderCount}</h2>
									<div className="caption-R color-primary-50">Active Minders</div>
								</div>

								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{totalSoldTicketCount}</h2>
									<div className="caption-R color-primary-50">Tickets sold in {currentMonth}</div>
								</div>

								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{adminRevenuePrice} <sub className="caption-R">EUR</sub></h2>
									<div className="caption-R color-primary-50">Sales in {currentMonth}</div>
								</div>
							</div>
						</div>
						
						<div className="card-details rounded-4 bg-white p-4">
							<div className="d-flex justify-content-between align-items-center">
								<div>
									<h5 className="heading-4 color-primary-50">Activity feed</h5>
								</div>
							</div>

							<div className="list_view">
								{[0,1,4].map((iem, index) => {
									return (
										<>
											<div className="d-flex justify-content-between align-items-center">
												<div>
													<div className="caption-R color-primary-50 py-2">Name</div>
													
													<button 
														className="ax-button subtitle-B color-primary-50 text-left" 
														onClick={() => onHandleServiceTitleClick()}
													>
													
													</button>
												</div>

												<div><ActionMenu /></div>
											</div>
											<hr />
										</>
									)
								})}
							</div>

						</div> 
					</div>
				</div>

				<div className="details-container">
					<div className="bg-white rounded-4 h-100 details_card_container">
						<div className="flex_container_70  p-5 w-100">
							<h4 className="heading-4 color-primary-50">Catalysts</h4>
							<CatalystView />
						</div>
					</div>

					<div className="bg-white rounded-4 h-100 details_card_container">
						<div className="flex_container_70  p-5 w-100">
							<div className="w-100 d-flex justify-content-between">
								<h4 className="heading-4 color-primary-50">Services</h4>
								<div>
									<SelectwithoutLabel
										isDisabled={true}
										name="serviceStatus"
										value = {serviceStatus}
						                onInputChange = {val => setServiceStatus(val)}
						                list = {Service_Status_Array} 
				            		/>
		              			</div>
							</div>
							<ServiceView />
						</div>
					</div>
					<div className="bg-white rounded-4 h-100 details_card_container">
						<div className="flex_container_70 p-5 w-100">
							<h4 className="heading-4 color-primary-50">Customers</h4>
							<CustomerView />
						</div>
					</div>
					<div className="bg-white rounded-4 h-100 details_card_container">
						<div className="flex_container_70  p-5 w-100">
							<h4 className="heading-4 color-primary-50">Payments</h4>
							<PaymentView />
						</div>
					</div>
				</div>

				<div className="my-5">
					<TabView 
						children = {(service_Type) => TabPanel(service_Type)} 
						tabs = {Service_Tab_List} 
						onTabClick = {(val) => setActiveTab(val)}
						service_Type = {service_Type}
					/>
				</div>

				{(loading || catalystLoading) && <Loading />}
				{(error || catalystError || isError) && <CustomizedSnackbars isShow={(error || catalystError || isError)} text="Something went wrong. Please try after sometime." />}     
    
				{isShowServiceDialog && <AddServiceDialog isOpen = {isShowServiceDialog} onHandleClose={() => setShowServiceDialog(false)} />}
			
			</div>
		</div>
	)
}

export default AdminDashboard;