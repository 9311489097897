import * as React from 'react';
import TextField from '@mui/material/TextField';

const Textarea = (props) => {
  const { 
    classVal = "",
    isError = false, 
    label = "", 
    type="text", 
    name="", 
    value="", 
    isDisabled=false, 
    isShowLabel, 
    isRequired=false,
    placeHolder="",
    onInputChange,
    maxlength="300"
  } = props;

  const onHandleChange = (e) => {
    onInputChange(e);
  }

  return (
    <>
      <label className="color-primary-10 mb-2">{label}</label>
      <textarea 
        name={name}
        value={value} 
        type="text"
        className="w-100" 
        rows="4" 
        cols="100" 
        placeholder={placeHolder} 
        onChange={(e) => onInputChange(e)}
        maxlength={maxlength}
      />
    </>
  );
}

export default Textarea;
