import React, {useEffect, useState, useRef} from 'react';
import DialogContent from '@mui/material/DialogContent';
import BasicSwitch from '../../../components/basicSwitch/BasicSwitch';
import Input from '../../../components/input/Input';
import FloatSelectView from '../../../components/floatSelectView/FloatSelectView';
import PaymentDetailsView from '../../../components/paymentDetailsView/PaymentDetailsView';
import SubscribePaymentDetailsView from '../../../components/subscribePaymentDetailsView/SubscribePaymentDetailsView';
import {CountryList} from '../../../utils/DefaultStates';
import {InitialPaymentDetails, InitialPaymentBillingAddress} from '../../../utils/DefaultInitialValues';

const PaymentView = (props) => {
  const dialogRef = useRef();
  
  const {
    pageName = "onBoarding" , 
    userDetails = {}, 
    onHandleAcountCheckboxChange,
    overallPrice = 0,
    onHandleAcountChange,
    userErrors = {},
    isPlanChange = false
  } = props;

  const [paymentMode, setPaymentMode] = useState(false);

  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {
    }
  }, []);

  const {
    accountStreetError = false,
    accountCityError = false,
    accountPostcodeError = false,
    accountCountryError = false
  } = userErrors || {};

  const {
    street1 = "",
    street2 = "",
    city = "",
    postcode = "",
    country = ""
  } = userDetails?.accountDetails || {};

  return (
    <div className="dialog-body" ref={dialogRef}>
      <div className="container dialog_padding">
        
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="payment">
              <div className="payment_left_view">
                <div className="payment_card_container">
                  <h4 className="color-primary-50 heading-4">Add card details</h4>
                  <div className="body-R color-primary-50 my-3">We currently support the bank cards from VISA, Mastercard, American Express, and UnionPay.</div>
                </div>
              
                <div className="payment_card_container">
                  <div className="color-primary-50 subtitle-B">Address</div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="street1" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "street no" 
                          name="street1" 
                          classVal="w-100"
                          value={street1}
                          onInputChange = {e => onHandleAcountChange(e)}
                        />
                        {accountStreetError && <div className="text-danger pt-1">! Street is a required field</div>}
                      </div>
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="postcode" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "Zip/postal code" 
                          name="postcode" 
                          classVal="w-100"
                          value={postcode}
                          onInputChange = {e => onHandleAcountChange(e)}
                        />
                        {accountPostcodeError && <div className="text-danger pt-1">! Postal code is a required field</div>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="city" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "City" 
                          name="city" 
                          classVal="w-100"
                          value={city}
                          onInputChange = {e => onHandleAcountChange(e)}
                        />
                        {accountCityError && <div className="text-danger pt-1">! City is a required field</div>} 
                      </div>
                      <div className="col-lg-6 my-2">
                        <FloatSelectView
                          name = "country"
                          label = "Country"
                          list = {CountryList}
                          value = {country}
                          onInputChange = {e => onHandleAcountChange(e)} 
                  
                        />
                        {accountCountryError && <div className="text-danger pt-1">! Country is a required field</div>} 
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="color-primary-50 body-R d-flex justify-content-between align-items-center">
                  <div>
                    Save this payment details to expedite the future checkout process. Please be informed that we take extreme precautions to safeguard your details.
                  </div>

                  <BasicSwitch 
                    name = "isSavedPaymentDetails"
                    value = {isSavedPaymentDetails}
                    onInputChange = {(e, value) => onHandleAcountCheckboxChange(e, value)} 
                  />    
                </div>*/}
            </div>

            <div className="payment_right_view">
              <div className="row p-4 rounded-4 coupon w-100">
              <div className="d-flex justify-content-between">
                <Input 
                  type="text" 
                  id="coupon" 
                  placeHolder = "xxxx-xxxx" 
                  label = "coupon" 
                  name="coupon" 
                  classVal="w-100"
                  value=""
                  isDisabled
                  onInputChange = {e => console.log(e)}

                /> 
                <button className="btn btn-outline-warning ms-3" disabled>Apply</button>
              </div>
              </div>

              {pageName !== "onBoarding" && <PaymentDetailsView />}
              {pageName === "onBoarding" && <SubscribePaymentDetailsView userDetails={userDetails} />} 
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>      
  )
}

export default PaymentView;