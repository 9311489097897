import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loading from  '../../components/loading/Loading';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import {headerOption} from '../../utils/DefaultInitialValues';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {baseURL} from '../../actions/Constants';

import Slide from '@mui/material/Slide';

import PurchaseView from './PurchaseView';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SIBSStatus = () => {
      const [open, setOpen] = useState(true);
      const [isLoading, setLoading] = useState(false);
      const [isError, setError] = useState(false);
      const {id} = useParams();


      console.log("SIBS Status", id);
      const textBeforeQuestionMark = id.split('?')[0];
      console.log(textBeforeQuestionMark);
      const transactionId = textBeforeQuestionMark;

      const [msg, setMsg] = useState("");

      const navigate = useNavigate();

      const getTransactionStatus = async () => {
        setLoading(true);

        const URL = `${baseURL}/minded-services/check_transaction_status/${transactionId}`;
        //const URL = `https://spg.qly.site1.sibs.pt/api/v2/payments/${transactionId}/status`;

        /*fetch(apiUrl, {
                method: 'POST', // Specify the method as POST
                headers: {
                    'Authorization': "Bearer 0267adfae94c224be1b374be2ce7b298f0.eyJlIjoiMjAwMTM0NDM4MzA5MiIsInJvbGVzIjoiTUFOQUdFUiIsInRva2VuQXBwRGF0YSI6IntcIm1jXCI6XCI5OTk5OTk5XCIsXCJ0Y1wiOlwiNTg3NzlcIn0iLCJpIjoiMTY4NTcyNTE4MzA5MiIsImlzIjoiaHR0cHM6Ly9xbHkuc2l0ZTEuc3NvLnN5cy5zaWJzLnB0L2F1dGgvcmVhbG1zL0RFVi5TQk8tSU5ULlBPUlQxIiwidHlwIjoiQmVhcmVyIiwiaWQiOiJ6QzMwSEZUMmlQZmZkMGNjZWU5OWQ1NGU1ZjllNjM2MWJiYTA0MmExNDEifQ==.65ba580e790f747d27dc42cd87fbdbe0745be408056b78fad46416187a2346ee97aceda47de736c318dce39211a055b91b42577aef802339836f9c0867c1c4fe",
                    'Content-Type': 'application/json',
                    'X-IBM-Client-Id': "a96238b8-f4f9-48a3-973f-1fb1dfa3dddb"
                },
                'mode': 'cors',
                body: JSON.stringify(data)  
            })*/

        return await fetch(URL)
        .then(response => {
          const resp = response;

          const {status = ""} = resp;
          setLoading(false);
          console.log("transaction status --------------->", status);
          
          if(status === 200 || status === 201) {
            return response.json().then(res => {
              // setError(false);
              console.log(res);
              setMsg(res);
            });
            
          } else {
            //Error
            setError(true);
          }
        })
        .catch((error) => {
          console.log('error bookSchedule', error);
          setError(true);
          setMsg(error?.returnStatus?.statusMsg);
        });
      }

      const retry = () => {
        getTransactionStatus();
      }

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        //setOpen(false);
       // onHandleClose();
      };

      const handleDone = () => {
        navigate("/dashboard")
      }

      useEffect(() => {
        getTransactionStatus();
      }, [])

      const URLAPI = `https://spg.qly.site1.sibs.pt/api/v2/payments/${transactionId}/status`;

      return (
      <div className="full-dialog">
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >

        <DialogActions className="d-flex justify-content-center align-items-center p-4">
          <h4 className="heading-4 color-primary-50">
            Confirmation
          </h4>
        </DialogActions>

        <DialogContent>
          <div>Received the Response for Transition {transactionId} - {URLAPI}</div>
          <div>{JSON.stringify(msg)}</div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className="card-container d-flex justify-content-end align-items-center dialog_padding">
              <div className="d-flex">
                <button className="mx-3 secondary_btn capitalize" onClick={retry}>Retry</button>
                <button className="mx-3 secondary_btn capitalize" onClick={handleDone}>Done</button>
              </div>
            </div>
          </div>
        </DialogActions>

        {isLoading && <Loading />}
        {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />} 
      </Dialog>
    </div>
  );
}

export default SIBSStatus;