import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import {useDispatch} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Loading from '../../../components/loading/Loading';
import GuidelineInfo from './GuidelineInfo';
import PlanDetails from './PlanDetails';
import PaymentView from './PaymentView';

import UserDetails from './UserDetails';
import AddObjective from './AddObjective';

import Confirmation from './Confirmation';

import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import CatalystProfileDetails from './CatalystProfileDetails';
import PayWidgetView from '../../payWidgetView/PayWidgetView';
import ArrowBack from '@mui/icons-material/ArrowBack';

import {baseURL} from '../../../actions/Constants';

import {InitialUserDetails, InitialUserErrors} from '../../../utils/DefaultInitialValues';
import {getBoardingHeaderText, getBoardingCTAText, isShowBackBtn, covertToLower, isEmptyJson, getEndEpochAfterGivenDate} from '../../../utils/util';

import {saveAdmin} from '../../../actions/admin.actions';
import brown_cancel from '../../../assets/brown_cancel.png';
import './onBoading.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OnBoardingDialog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveAdminAction = saveAdmin;

  const {isOpen = false, onHandleClose, navigateStepper = 0, userinfo = InitialUserDetails} = props;
  
  const [open, setOpen] = useState(false);
  const [isPlanChange, setPlanChange] = useState(false);
  const [stepperView, setStepperView] = useState(1);
  const [userDetails, setUserDetails] = useState(userinfo);
  const [userErrors, setUserErrors] = useState(InitialUserErrors);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [isGuidelineChecked, setGuidelineChecked] = useState(false);
  const [isProfilePicUpdate, setProfilePicUpdate] = useState(false);
  const [billingAddress, setBillingAddress] = useState({});

  const [newProfilePic, setProfileImage] = useState("");
  const [overallPrice, setOverallPrice] = useState(0);
  let isEdit = false;

  useEffect(() => {
    if(navigateStepper !== 0) {
      setStepperView(navigateStepper);
    }
  }, [navigateStepper]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /*const handleClickOpen = () => {
    setOpen(true);
  };*/

  const handleClose = () => {
    setOpen(false);
    onHandleClose();
  };

  const handleBack = () => {
    setStepperView(stepperView - 1);
  }

  const onHandleChange = (e) => {
    setUserDetails({...userDetails, [e.target.name] : e.target.value});
  }

  const onHandleProfileChange = (e) => {
    setUserDetails({...userDetails, profileDescription : {...userDetails.profileDescription, [e.target.name] : e.target.value}});
  }

  const onHandleProfileImageChange = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();

    reader.onload = function(event) {
      //setFile(e.target.result);
    };
    reader.readAsDataURL(file);

    const extention = file.name.split('.').pop();

    if(extention !== "png" && extention !== "jpg" ) {
      window.alert("File does not support. You must use .png or .jpg ");
      return false;
    }
    if(file.size > 2097152) {
      window.alert("Image size should not be more than 2 MB");
      return false;
    }

    setProfilePicUpdate(true);
    const newPic = file;
    setProfileImage(newPic);
    //setUserDetails({...userDetails, profileDescription : {...userDetails.profileDescription, "profilePic" : newPic}});
  }

  const onHandleCatalystDetailsChange = (e) => {
    setUserDetails({...userDetails, catalystDetails : {...userDetails.catalystDetails, [e.target.name] : e.target.value}});
  }

  const onHandlePlanDetailsChange = (plan) => {
    setPlanChange(true);
    setUserDetails({...userDetails, catalystDetails : {...userDetails.catalystDetails, planDetails : plan}});
  }

  const onHandleAddressChange = (e) => {
    setUserDetails({...userDetails, address : {...userDetails.address, [e.target.name] : e.target.value}});
  }

  const onHandleAcountChange = (e) => {
    setUserDetails({...userDetails, accountDetails : {...userDetails.accountDetails, [e.target.name] : e.target.value}});
  }

  const onHandleProfileCompanyChange = (e) => {
    setUserDetails({
      ...userDetails, 
      profileDescription : {...userDetails.profileDescription, 
      companyDetails : {...userDetails.profileDescription.companyDetails, [e.target.name] : e.target.value}}
    });
  }

  const onHandleProfileSocialChange = (e,index) => {
    const newArray = [...socialMediaHandles];
    newArray[index].link = e.target.value;

    setUserDetails({
      ...userDetails, 
      profileDescription : {...userDetails.profileDescription, socialMediaHandles : newArray}
    });  
  }

  const onDropdownChange = (name, list = []) => {
    let latestList = [];
    
    if(name === "languages") {
      latestList = list.map((item, index) => {
        const obj = {};
        obj.value = item.value;
        obj.id = item.id;
        return obj;
      });
    } else {
      latestList = list.map((item, index) => {
        const obj = {};
        obj.name = item.name;
        obj.id = item.id;
        return obj;
      });
    }

    setUserDetails({...userDetails, catalystDetails: {...userDetails.catalystDetails, [name] : latestList}});
  }

  const onObjectiveDropdownChange = (name, list = []) => {
    setUserDetails({...userDetails, profileDescription: {...userDetails.profileDescription, [name] : list}});
  }

  const addLocation = () => {
    const newArray = [...locations];

    newArray.push({
      "address" : "",
      "city" : "",
      "country" : "",
      "coordinate" : {}
    });

    setUserDetails({...userDetails, catalystDetails: {...userDetails.catalystDetails,  locations : newArray}});
  }

  const onHandleLocationChange = (e, index = 0) => {
    const newArray = [...locations];
    
    newArray[index].city = e.target.value;
    setUserDetails({...userDetails, catalystDetails: {...userDetails.catalystDetails, locations : newArray}});
  }

  const onHandleAcountCheckboxChange = (e, val) => {
    setUserDetails({...userDetails, accountDetails : {...userDetails.accountDetails, [e.target.name]: val}});
  }

  const onHandleAllService = () => {
    handleClose();
    navigate("/events-classes");
  }

  const goNext = () => {
    const isValid = validate();
    //const isTrial = subscriptionTrialDuration !== 0;
    
    if(
      (stepperView === 7 && formattedRole === "catalyst") 
      || (stepperView === 4 && (formattedRole === "admin" || formattedRole === "customer"))) {
      handleClose();
      navigate("/dashboard");
    }

    if(isValid) {
      if(formattedRole === "customer" || formattedRole === "admin") {
        stepperView === 3 ? updateUser() : setStepperView(stepperView + 1);
      } else if(formattedRole === "catalyst") {
        if(stepperView === 2) {
          navigateStepper === 2 ?  setStepperView(stepperView + 3) : setStepperView(stepperView + 1);
        } else if(stepperView === 4 && isJump) {
          updateUser(true); //Amit : Skip payment view
        } else if(stepperView === 5) {
          updateUser();
        } else if(stepperView === 6) {
        } else {
          setStepperView(stepperView + 1);
        }
      }
    }
  }

  const insertOrUpdatePurchase = async (userInfo = {}) => {
    const URL = `${baseURL}/minded-services/insertOrUpdatePurchase`;
    setLoading(true);
    const purchaseInfo = {};
    purchaseInfo.userId = userInfo?.userId || "";
    purchaseInfo.userName = name || "";

    //const convertedString = planDetails?.subscriptionPrice?.toString().replace(',', '.');
    //const totalPrice = parseFloat(convertedString) || 0;
    purchaseInfo.purchaseAmount = subscriptionTotalPrice;

    const obj = {};
    
    purchaseInfo.paymentDetails = [];

    obj.pricingType = "Subscription";
    obj.subscriptionId = subscriptionId;
    obj.subscriptionName = subscriptionName;
    obj.subscriptionPaymentPlan = subscriptionPaymentPlan;
    obj.subscriptionPrice = subscriptionPrice;
    obj.subscriptionTotalPrice = subscriptionTotalPrice;
    obj.subscriptionPriceCurrency = subscriptionPriceCurrency;
    obj.subscriptionTrialDuration = subscriptionTrialDuration;
    purchaseInfo.purchaseAdditionalDetails = obj;
    //purchaseInfo.purchaseDetails = obj;

    purchaseInfo.paymentDetails.push(obj);

    purchaseInfo.purchaseDate = new Date().getTime();
    purchaseInfo.purchaseExpiryDate = getEndEpochAfterGivenDate(subscriptionTrialDuration);
    purchaseInfo.paymentStatus = "success";
    //purchaseInfo.purchaseStatus = "pending_confirmation";
    purchaseInfo.purchaseStatus = isEdit ? "pending_confirmation" : "completed";
    purchaseInfo.trail = [];

    console.log("insertOrUpdatePurchase", purchaseInfo);

    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(purchaseInfo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;

      const {status = ""} = resp;

      if(status === 200 || status === 201) {
        return response.json().then(res => {
          setLoading(false);
          initiate_minded_payment_catalyst_subscription(res?.purchaseId);
        });
      } else {
        //Error
        setLoading(false);
        setError(true);
      }
    })
    .catch((error) => {
      //console.log('error getSchedules', error);
      console.log('error addBooking', error);
      setLoading(false);
      setError(true);
    });
  }

  const initiate_minded_payment_catalyst_subscription = async (purchaseId = "") => {
    const URL = `${baseURL}/minded-services/initiate_minded_payment_catalyst_subscription`;
    setLoading(true);
    //const convertedString = planDetails?.subscriptionPrice?.toString().replace(',', '.');

    // Convert the string back to a number
    //const convertedNumber = Number(convertedString);
    const totalPrice = subscriptionTotalPrice || 0;
    setOverallPrice(totalPrice);

    const obj = {
      purchaseId: purchaseId,
      description: name || "AK",
      value: parseFloat(totalPrice),
      currency:"EUR",
      customer:{
        customerInfo:{
        customerName: name,
        customerEmail: email,
          billingAddress: {
            street1: accountDetails?.street1,
            street2: "",
            city: accountDetails?.city,
            postcode: accountDetails?.postcode,
            country: accountDetails?.country
          }
        }
      }
    }

    console.log("initiate_minded_payment_catalyst_subscription request Data", obj);
    
    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;
     
      setError(false);
      setLoading(false);
      const {status = ""} = resp;

      if(status === 200 || status === 201) {
        return response.json().then(res => {
          //const checkoutRes = res;
          console.log("initiate_minded_payment_catalyst_subscription response", res);
          localStorage.setItem("checkout", JSON.stringify(res));
          localStorage.setItem("purchaseId", purchaseId);
          localStorage.setItem("transactionID", res?.transactionID);
          localStorage.setItem("transactionSignature", res?.transactionSignature);
          localStorage.setItem("formContext", res?.formContext);
          localStorage.setItem("customerData", JSON.stringify(accountDetails));
          localStorage.setItem("actualAmount", parseFloat(totalPrice));
          localStorage.setItem("isRecurring", true);

          if(paymentDetails[0] && paymentDetails[0].subscriptionTrialDuration !== 0) {
            setStepperView(stepperView + 2);
          } else {
            setStepperView(stepperView + 1);
          }
        });
      } else {
        //Error
        setError(true);
      }
    })
    .catch((error) => {
      //console.log('error getSchedules', error);
      console.log('error initiate_minded_payment_catalyst_subscription', error);
      setLoading(false);
      setError(true);
    });
  }

  const onHandleSkipSubmit = () => {
    const isValid = validate();
    if(isValid) {
      updateUser(true);
    }
  }

  const validate = () => {
    let isFormValid = true;

    let isGuidelineInvalid = false;
    let isNameInvalid = false;
    let isPhoneInvalid = false;
    let isStreetInvalid = false;
    let isApartmentInvalid = false;
    let isStateInvalid = false;
    let isCityInvalid = false;
    let isPincodeInvalid = false;
    let isCountryInvalid = false;
    let isAboutInvalid = false;
    let isExpertiseInvalid = false;
    let isLanguagesInvalid = false;

    let isSubscribeInvalid = false;

    let isAccountStreetInvalid = false;
    let isAccountCityInvalid = false;
    let isAccountPostcodeInvalid = false;
    let isAccountCountryInvalid = false;

    if(formattedRole === "customer" || formattedRole === "admin") {
      if(stepperView === 1) {
        if(!isGuidelineChecked) {
          isGuidelineInvalid = true;
          isFormValid = false;
        }
      } else if(stepperView === 2) {
        if(name === "") {
          isNameInvalid = true;
          isFormValid = false;
        }
      }
    } else if(formattedRole === "catalyst") {
      if(stepperView === 1) {
        if(!isGuidelineChecked) {
          isGuidelineInvalid = true;
          isFormValid = false;
        }
      } else if(stepperView === 2) {
       
        //const isEmpty = isEmptyJson(userDetails?.catalystDetails?.planDetails);
        if(isEmptyJson(planDetails)) {
          isSubscribeInvalid = true;
          isFormValid = false;
        }
      } else if(stepperView === 3) {
        if(name === "") {
          isNameInvalid = true;
          isFormValid = false;
        }
        if(phone === "") {
          isPhoneInvalid = true;
          isFormValid = false;
        }
        if(street === "") {
          isStreetInvalid = true;
          isFormValid = false;
        }

        if(city === "") {
          isCityInvalid = true;
          isFormValid = false;
        }
        if(state === "") {
          isStateInvalid = true;
          isFormValid = false;
        }
        if(pincode === "") {
          isPincodeInvalid = true;
          isFormValid = false;
        }
        if(country === "") {
          isCountryInvalid = true;
          isFormValid = false;
        }
        if(aboutMe === "") {
          isAboutInvalid = true;
          isFormValid = false;
        }
      } else if(stepperView === 4) {
        if(serviceCategory?.length === 0) {
          isExpertiseInvalid = true;
          isFormValid = false;
        }
        if(languages?.length === 0) {
          isLanguagesInvalid = true;
          isFormValid = false;
        }
      } else if(stepperView === 5) {
        
        if(street1 === "") {
          isAccountStreetInvalid = true;
          isFormValid = false;
        }

        if(accountCity === "") {
          isAccountCityInvalid = true;
          isFormValid = false;
        }

        if(accountPostcode === "") {
          isAccountPostcodeInvalid = true;
          isFormValid = false;
        }

        if(accountCountry === "") {
          isAccountCountryInvalid = true;
          isFormValid = false;
        }
      }

      if(isFormValid && stepperView === 5) {
      }
    }

    setUserErrors({
      ...userErrors,
      guidelineError : isGuidelineInvalid,
      nameError: isNameInvalid,
      phoneError: isPhoneInvalid,
      countryError: isCountryInvalid,
      streetError: isStreetInvalid,
      apartmentError: isApartmentInvalid,
      stateError: isStateInvalid,
      cityError: isCityInvalid,
      pincodeError: isPincodeInvalid,
      aboutError: isAboutInvalid,
      subscribeError : isSubscribeInvalid,
      expertiseError: isExpertiseInvalid,
      languageError: isLanguagesInvalid,
      accountStreetError: isAccountStreetInvalid,
      accountCityError: isAccountCityInvalid,
      accountPostcodeError: isAccountPostcodeInvalid,
      accountCountryError: isAccountCountryInvalid
    });

    return isFormValid;
  }

  const updateUser = async (isSubmit = false) => {
    if(formattedRole === "customer") {
      userDetails.status = "active";
    } else if(formattedRole === "catalyst") {
      if(!userDetails?.userId) {
        userDetails.catalystDetails.planDetails.planExpireDate = getEndEpochAfterGivenDate(subscriptionTrialDuration);
      }
      if(userDetails?.status && userDetails?.status !== "pending_payment") {
        userDetails.status = "pending";
      }
    }
    
    isEdit = (userDetails?.userId && userDetails?.userId !== "");
    const URL = isEdit ?  `${baseURL}/minded-user/updateUserDetails` : `${baseURL}/minded-user/createUser`;
    setLoading(true);
    
    /*
      const d = new Date();
      userDetails.createdOn = d.getTime().toString();
      userDetails.updatedOn = d.getTime().toString();
    */
    //delete userDetails["updatedOn"];
    //delete userDetails["createdOn"];

    let updatedProfile = "";

    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(userDetails),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;
      const {status = ""} = resp;

      setLoading(false);

      if(status === 200 || status === 201) {
        return response.json().then(res => { 
          setUserDetails((prevState) => ({
            ...prevState,
            res,
            userId: res?.userId
          }));
          dispatch(saveAdminAction(res));

          if(isProfilePicUpdate) {
            uploadProfileImage(res, updatedProfile, isSubmit);
          } else {
            console.log("Else isSubmit=", isSubmit);
            if(isSubmit) {
              if(formattedRole === "catalyst") {
                setStepperView(7);
              } else {
                setStepperView(4);
              }
            } else {
              if(formattedRole === "catalyst") {
                insertOrUpdatePurchase(res);
              } else {
                setStepperView(stepperView + 1);
              }
            }
          }
        });
      } else {
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error addService/updateService', error);
      setLoading(false);
    });
  }

  const uploadProfileImage = async (data = userDetails, updatedProfile, isSubmit = false) => {
    const URL = `${baseURL}/minded-user/uploadUserProfilePicture`;
    setLoading(true);

    var imageFormData = new FormData();

    imageFormData.append('userId', data?.userId);
    imageFormData.append("file", newProfilePic);

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: imageFormData // body data type must match "Content-Type" header
    })
    .then(response => {
      const resp = response;
      const {status = ""} = resp;

      setLoading(false);

      if(status === 200) {
        return response.json().then(res => { 
          dispatch(saveAdminAction(res));
          if(isSubmit) {
            setStepperView(7);
          } else {
            insertOrUpdatePurchase(res);
          }
        });
      } else {
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error uploadProfileImage', error);
      setLoading(false);
    });
  }

  const isShowSkipButton = () => {
    if(formattedRole === "catalyst") {
      if(stepperView !== 7 && userDetails && userDetails?.userId && userDetails?.userId !== "") {
        if(stepperView <= 2 || navigateStepper === 2) {
          return false;
        } else if (isJump && stepperView === 4) {
          return false;
        }
        return true;
      }
    } else if(formattedRole === "customer") {
      if(stepperView !== 4 && userDetails && userDetails?.userId && userDetails?.userId !== "") {
        return true;
      }
    }

    return false;
  }

  const {
    role = "customer",
    name = "",
    phone = "",
    email = "",
    accountDetails = {},
    catalystDetails = {},
    paymentDetails = {}
  } = userDetails || {};

   const {
    locations = [
      {
        address : "",
        city : "",
        country : "",
        coordinate : {}
      }
    ],
    languages = [],
    serviceCategory = [],
    planDetails = {}
  } = catalystDetails || {};

  const {
    subscriptionId = "",
    subscriptionName  = "",
    subscriptionPaymentPlan = "",
    subscriptionPrice = "",
    subscriptionTotalPrice = 0,
    subscriptionPriceCurrency = "",
    subscriptionTrialDuration = ""
  } = planDetails || {};

  const {
    street = "",
    city = "",
    state = "",
    pincode = "",
    country = ""
  } = userDetails?.address || {};

  const {
    street1 = "",
    city:accountCity = "",
    postcode:accountPostcode = "",
    country: accountCountry = ""
  } = userDetails?.accountDetails || {};

  const {
    socialMediaHandles = [
      {
        name : "",
        link : ""
      },
      {
        name : "",
        link : ""
      }
    ],
    aboutMe = ""
  } = userDetails?.profileDescription || {};

  const formattedRole = covertToLower(role);
  const isJump = (formattedRole === "catalyst") && !isPlanChange && userinfo && userinfo?.id !== "";
  const isSkip = isJump;

  const submitText = getBoardingCTAText(stepperView, formattedRole, isSkip);
  const showBackBtn = isShowBackBtn(stepperView, formattedRole, "ON_BOARDING");
  const title = getBoardingHeaderText(stepperView, formattedRole);

  return (
    <div className="full-dialog">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >

        <DialogActions className="w-100 p-4">
          <div className="w-100 d-flex justify-content-between align-items-center px-0 px-lg-5 px-md-3">
            <div>
              {(showBackBtn && navigateStepper !== 2) && <button
                onClick={handleBack}
                aria-label="Back"
                className="ax-button color-primary-50"
              >
                <ArrowBack className = "color-primary-50" />
              </button>
            }
            </div>

            <h4 className="heading-4 color-primary-50">
              {title}
            </h4>

            <div>
              <button     
                onClick={handleClose}
                aria-label="close"
                className="close_icon ax-button"
              >
                <img src={brown_cancel} className="close_icon" alt="Close" />
              </button>
            </div>
          </div>
        </DialogActions>
        
        <DialogContent>
          {stepperView === 1 && <GuidelineInfo 
            userDetails = {userDetails} 
            isGuidelineChecked = {isGuidelineChecked}
            setGuidelineChecked = {(val) => setGuidelineChecked(val)}
            userErrors = {userErrors}
      
          />}
          
          {(formattedRole === "customer" || formattedRole === "admin") && stepperView === 2 && <UserDetails 
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandleChange = {e => onHandleChange(e)}
            onHandleAddressChange = {e => onHandleAddressChange(e)}
            onHandleProfileChange = {e => onHandleProfileChange(e)}
            onHandleProfileImageChange = {onHandleProfileImageChange}
            onDropdownChange = {(name, list) => onDropdownChange(name, list)}
            newProfilePic = {newProfilePic}
          />}

          {(formattedRole === "customer" || formattedRole === "admin") && stepperView === 3 && <AddObjective 
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandleChange = {e => onHandleChange(e)}
            onDropdownChange = {(name, list) => onObjectiveDropdownChange(name, list)}
          />}

          {(formattedRole === "customer" || formattedRole === "admin") && stepperView === 4 && <Confirmation
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandleChange = {e => onHandleChange(e)}
            onHandleAllService = {onHandleAllService}
          />}

          {formattedRole === "catalyst" && stepperView === 2 && <PlanDetails 
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandlePlanDetailsChange = {plan => onHandlePlanDetailsChange(plan)}
          />}

          {formattedRole === "catalyst" && stepperView === 3 && <UserDetails 
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandleChange = {e => onHandleChange(e)}
            onHandleAddressChange = {e => onHandleAddressChange(e)}
            onHandleProfileCompanyChange = {e => onHandleProfileCompanyChange(e)}
            onHandleProfileSocialChange = {(e,index) => onHandleProfileSocialChange(e,index)}
            onHandleProfileChange = {e => onHandleProfileChange(e)}
            onHandleProfileImageChange = {e => onHandleProfileImageChange(e)}
            onDropdownChange = {(name, list) => onObjectiveDropdownChange(name, list)}
            newProfilePic = {newProfilePic}
          />}
          
          {formattedRole === "catalyst" && stepperView === 4 && <CatalystProfileDetails 
            userDetails = {userDetails} 
            userErrors = {userErrors}
            addLocation = {addLocation}
            onHandleChange = {e => onHandleChange(e)} 
            onDropdownChange = {(name, list) => onDropdownChange(name, list)}
            handleLocationChange = {(e, index) => onHandleLocationChange(e, index)} 
            onHandleCatalystDetailsChange = {e => onHandleCatalystDetailsChange(e)}
            onHandleProfileChange = {e => onHandleProfileChange(e)}
            onHandleProfileCompanyChange = {e => onHandleProfileCompanyChange(e)}
            onHandleProfileSocialChange = {(e,index) => onHandleProfileSocialChange(e,index)}
          />}

          {formattedRole === "catalyst" && stepperView === 5 && <PaymentView
            pageName = "onBoarding" 
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandleAcountChange = {e => onHandleAcountChange(e)}
            overallPrice = {overallPrice}
            onHandleAcountCheckboxChange = {(e, val) => onHandleAcountCheckboxChange(e,val)}
            onUpdateBillingAddress = {(data) => setBillingAddress(data)}
          />}

          {formattedRole === "catalyst" && stepperView === 6 && <PayWidgetView />}

          {formattedRole === "catalyst" && stepperView === 7 && <Confirmation
            userDetails = {userDetails} 
            userErrors = {userErrors} 
            onHandleChange = {e => onHandleChange(e)}
            onHandleAllService = {onHandleAllService}
          />}
        </DialogContent>

        <DialogActions className="d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className=" d-flex justify-content-between align-items-center dialog_padding">
              <div className="d-flex ">
              </div>
              
              {stepperView !== 6 && <div className="d-flex">
                {isShowSkipButton()  && <button className="me-3 tertiary_btn capitalize color-primary-50" onClick={onHandleSkipSubmit}>Skip & submit</button>}
                <button className="secondary_btn capitalize" onClick={goNext}>{submitText}</button>
              </div>}
              
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {isLoading && <Loading />}
      {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
    </div>
  );
}

export default OnBoardingDialog;